import React from 'react';
import disagreementimg from 'assets/home-new/disagreement.svg';
import effortimg from 'assets/home-new/effort.svg';
import timeimg from 'assets/home-new/time.svg';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

const Challenges = () => {
  return (
    <div className="container challenges_wrapper">
      <h2 className="home_challenges-title">Common consulting challenges</h2>
      <div className="home_challenges-block-wrapper">
        <div className="home_challenges-block">
          <img src={timeimg} alt="time" />
          <h3 className="block_title">Slow strategy delivery</h3>
          <p className="block_text text-white">
            You can’t afford to spend weeks on a strategy. Requirements are constantly changing. You need to adapt and
            be agile.
          </p>
        </div>
        <div className="home_challenges-block">
          <img src={disagreementimg} alt="time" />
          <h3 className="block_title">Misalignment with client expectations</h3>
          <p className="block_text text-white">
            Meeting client expectations can feel like a moving target. Misunderstanding makes it challenging to deliver
            value.
          </p>
        </div>
        <div className="home_challenges-block">
          <img src={effortimg} alt="time" />
          <h3 className="block_title">AI is hard to use</h3>
          <p className="block_text text-white">
            It takes substantial time and effort to make AI understand your context in order to yield great results.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
