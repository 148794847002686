import React from 'react';
import Slider from 'react-slick';
import logo3 from 'assets/logos/logo-corebiz-global.png';
import logo6 from 'assets/logos/m_booth_logo-Photoroom.png';
import logo7 from 'assets/logos/mccann-paris-1556_1674827981-Photoroom.png';
import logo9 from 'assets/logos/Otherway-logo.png';
import logo10 from 'assets/logos/Revolut-Logo.png';
import logo11 from 'assets/logos/slove-logo-Photoroom.png';
import logo14 from 'assets/logos/wone_altavia_logo.png';

import './style.css';

const LogoSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    // slidesToShow: 9,
    variableWidth: true,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 5,
          // slidesToScroll: 3,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 4,
          // slidesToScroll: 2,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // slidesToShow: 3,
          // slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="steps_section">
      <div className="slider-container">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...settings}>
          <div className="img_container">
            <img src="assets/logos/thumbnail-1-1.png" alt="thumbnail-1-1.png" />
          </div>
          <div className="img_container">
            <img src={logo3} alt="logo3" />
          </div>
          <div className="img_container">
            <img src="assets/logos/mischief_usa_logo-Photoroom.png" alt="mischief_usa_logo-Photoroom.png" />
          </div>
          <div className="img_container">
            <img src={logo6} alt="logo6" />
          </div>
          <div className="img_container">
            <img src="assets/logos/Supercell_Logo.png" alt="Supercell_Logo.png" />
          </div>
          <div className="img_container">
            <img src="assets/logos/Inphluent_2.png" alt="Inphluent_2.png" />
          </div>
          <div className="img_container">
            <img src="assets/logos/Intuit-Logo-Photoroom.png" alt="Intuit-Logo-Photoroom.png" />
          </div>
          <div className="img_container">
            <img
              src="assets/logos/logo-duunitori-rgb-horisontal-inverse.png"
              alt="logo-duunitori-rgb-horisontal-inverse.png"
            />
          </div>
          <div className="img_container">
            <img src="assets/logos/logo-white.png" alt="logo-white.png" />
          </div>
          <div className="img_container">
            <img src={logo7} alt="logo7" />
          </div>
          <div className="img_container">
            <img src={logo9} alt="logo9" />
          </div>
          <div className="img_container">
            <img src={logo10} alt="logo10" />
          </div>
          <div className="img_container">
            <img src={logo11} alt="logo11" />
          </div>
          <div className="img_container">
            <img src={logo14} alt="logo14" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default LogoSlider;
