/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const SignUpValidation = Yup.object()
  .shape({
    name: Yup.string()
      .required('Please enter  name')
      .min(4, 'Name should not be less than 4 characters')
      .max(75, 'Name should not be more than 75 characters'),
    email: Yup.string().email().min(2, 'Too Short!').max(50, 'Too Long!').required('Please enter email'),
    password: Yup.string().required('Please enter password'),
  })
  .defined();

export const SignUpInitialValues = {
  name: '',
  email: '',
  password: '',
  prefilled: false,
};
