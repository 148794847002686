/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useRef, useState } from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import Lottie from 'react-lottie';
import { io } from 'socket.io-client';
import fileDownload from 'js-file-download';
import {
  MdBackspace,
  MdDownload,
  MdFitScreen,
  MdFullscreenExit,
  MdImage,
  MdSave,
  MdZoomIn,
  MdZoomOut,
} from 'react-icons/md';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { TopNavigationBar } from 'components';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY,
  routes,
  TOAST_CONFIG,
  USER_DATA_LOCAL_STORAGE_KEY,
} from 'constant';
import 'fetures/Workspace/style.css';
import {
  checkWorkspaceAccess,
  generateGroupNodesData as generateGroupNodesDataApi,
  updateWorkspaceApi as updateWorkspaceDataApi,
  getMindMapResults as generateMindmapNodesDataApi,
  getMindMapResultsCombined as generateCombinedNodesDataApi,
  getMindMapResultsExport as generateExportDiagramDocument,
} from 'api/mindMamp';
import { catchBlock } from 'utils';
import { getLoadingMessages } from 'api/config';
import actionButtonMenuAnimation from '../../assets/animations/Menu.json';
import ActionPlan from './Components/ActionPlan';
import Comments from './Components/Comments';

const WorkspaceEdit = () => {
  const navigate = useNavigate();
  const { id: workspaceId } = useParams();
  const { pathname } = useLocation();
  const goDiagram = useRef(null);
  const goDiagramWrapperRef = useRef(null);
  const moreActionsModalRef = useRef(null);
  const exportButtonsRef = useRef(null);
  const spinnerRef = useRef(null);
  const socket = useRef(null);
  const workspaceTitle = useRef(null);
  const authToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));

  const [isLoading, setIsLoading] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [workspaceData, setWorkspaceData] = useState(null);
  const [nodeMoreActionsData, setNodeMoreActionsData] = useState(null);
  const [isActionButtonMenuOpen, setIsActionButtonMenuOpen] = useState(false);
  const [actionButtonMenuDirection, setActionButtonMenuDirection] = useState(-1);
  const [isLoadingExportDiagram, setIsLoadingExportDiagram] = useState(false);
  const [isExportDiagramOpen, setIsExportDiagramOpen] = useState(false);
  const [isExportAllChecked, setIsExportAllChecked] = useState(false);
  const [actionNodes, setActionNodes] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState(null);
  const [isExportSubButtonOpen, setIsExportSubButtonOpen] = useState(false);

  const { isFetching: isFetchingLoadingMessages } = useQuery(['get-loading-messages'], () => getLoadingMessages(), {
    refetchOnMount: 'always',
    onSuccess: (response) => {
      setLoadingMessages(response?.messages || null);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { isLoading: isLoadingGetWorkspaceDetails } = useQuery({
    queryKey: ['get-workspace-details-with-access', workspaceId],
    queryFn: checkWorkspaceAccess,
    enabled: !!workspaceId,
    onSuccess: (response) => {
      const responseData = response.data;

      if (responseData.isAccess === false) {
        toast.error(response.message, TOAST_CONFIG);
        navigate(routes.dashBoard);
      } else if ((!authToken || authToken === '') && responseData?.work_space && !responseData.work_space.access_link) {
        handleRedirectToLogin();
      } else {
        workspaceTitle.current = responseData?.work_space?.title || null;
        setWorkspaceData(responseData || null);
      }
    },
    onError: (error) => {
      catchBlock(error);

      navigate(
        (!authToken || authToken === '') &&
          error?.response?.data?.data?.work_space &&
          !error.response.data.data.work_space.access_link
          ? routes.login
          : routes.dashBoard,
      );
    },
  });

  // Handler for Workspace Title Edit
  const handleSetWorkspaceTitleEditing = () => {
    setIsTitleEditing(true);
  };

  const handleBlurWorkspaceTitle = (e) => {
    if (e.target.value) {
      setIsTitleEditing(false);
      workspaceTitle.current = e.target.value;

      setTimeout(() => {
        handleUpdateWorkspaceData();
      }, [200]);
    }
  };

  const handleUpdateWorkspaceData = async () => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      if (diagram?.model?.nodeDataArray?.length > 0) {
        setIsLoading(true);

        await updateWorkspaceDataApi({
          id: workspaceData?.work_space?.id,
          title: workspaceTitle?.current || '',
          output: JSON.stringify(diagram.model.nodeDataArray).replaceAll('\\\\', '') || '',
          area: workspaceData?.work_space?.area || '',
          token: workspaceData?.work_space?.token || '',
          context: workspaceData?.work_space?.context || '',
        }).finally(() => setIsLoading(false));
      }
    }

    return;
  };

  // Handler for Redirect To Login
  const handleRedirectToLogin = () => {
    localStorage.setItem(FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY, pathname);
    navigate(routes.login);
  };

  // Handler for More Actions Modal
  const handleToggleMoreActionsModal = async (node = null, action = null) => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      diagram.nodes.each((n) => {
        const nodeLoader = n.findObject('nodeLoader');
        if (nodeLoader) {
          if (n.currentAnimation) n.currentAnimation.stop();

          if (node) {
            nodeLoader.visible = n.data.key !== node.data.key;
            nodeLoader.source = n.data.key !== node.data.key ? '' : '/assets/images/spinner.svg';
          } else {
            nodeLoader.visible = false;
            nodeLoader.source = '/assets/images/spinner.svg';
          }
        }
      });

      if (node) {
        toggleActionNode('add', [node.data.key]);

        setNodeMoreActionsData({
          workspace_id: workspaceData.work_space.id,
          node_id: node.data.key.toString(),
          action,
        });
      } else {
        if (nodeMoreActionsData?.node_id) {
          toggleActionNode('remove', [nodeMoreActionsData?.node_id]);

          const nodeData = diagram.findNodeForKey(nodeMoreActionsData?.node_id);
          const button = nodeData.findObject('nodeDescriptionCollapseButton');
          const description = nodeData.findObject('nodeDescription');

          if (button !== null && description !== null) {
            description.maxSize = new go.Size(325, 92);
            button.text = 'Show more';
          }
        }

        setNodeMoreActionsData(null);
        handleUpdateActionNodes('add', actionNodes, actionNodes);
      }
    }

    return;
  };

  const handleUpdateNodeMoreActions = (action) => {
    if (nodeMoreActionsData) setNodeMoreActionsData({ ...nodeMoreActionsData, action });
  };

  const handleToggleActionButtonMenu = () => {
    setActionButtonMenuDirection(actionButtonMenuDirection === 1 ? -1 : 1);
    setIsActionButtonMenuOpen(!isActionButtonMenuOpen);
  };

  // Handler for Workspace Actions
  const handleZoomInOutDiagram = (zoom) => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      var newScale = diagram.scale;

      if (zoom === 'in') newScale *= 1.2;
      else if (zoom === 'out') newScale /= 1.2;

      var animation = new go.Animation();
      animation.easing = go.Animation.EaseOutExpo;
      animation.duration = 900;

      animation.add(diagram, 'scale', diagram.scale, newScale);
      animation.start();
    }

    return;
  };

  const handleDiagramFitToScreen = () => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      diagram.padding = new go.Margin(250, 15, 15, 15);
      diagram.commandHandler.zoomToFit();
    }

    return;
  };

  const handleCenterDiagram = () => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      const firstNode = diagram.model.nodeDataArray.find(
        (node) => node.category === 'treeViewInitial' && node.name === 'mindmap',
      );

      if (firstNode) {
        diagram.scale = 1;
        diagram.commandHandler.scrollToPart(diagram.findNodeForKey(firstNode.key));
      }
    }

    return;
  };

  const handleExportDiagramImage = () => {
    if (goDiagram.current) {
      handleShowExportSubButton(!isExportSubButtonOpen);
      const diagram = goDiagram.current.getDiagram();

      setIsLoadingExportDiagram(true);

      diagram.padding = new go.Margin(50, 50, 50, 50);

      const bounds = diagram.documentBounds;
      const scale = 3;
      const padding = 50;

      const totalWidth = (bounds.width + 2 * padding) * scale;
      const totalHeight = (bounds.height + 2 * padding) * scale;

      var options = {
        scale,
        maxSize: new go.Size(totalWidth, totalHeight),
        size: new go.Size(totalWidth, totalHeight),
        position: new go.Point(bounds.x - padding, bounds.y - padding),
        background: '#09091d',
        showTemporary: true,
        returnType: 'blob',
        callback: async (imageData) => {
          var url = window.URL.createObjectURL(imageData);

          var a = document.createElement('a');
          a.href = url;
          a.download = `diagram-${workspaceData.work_space.id}-${moment().format('DDMMYYYYHHmmss')}.png`;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);

          setIsLoadingExportDiagram(false);
          toast.success('Image exported successfully.', TOAST_CONFIG);
        },
      };

      diagram.makeImageData(options);
    }

    return;
  };

  const handleClearDiagram = () => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, clear it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var nodes = diagram.model.nodeDataArray.filter((node) => {
            return !node.isGroup && node.category !== 'treeViewInitial' && node.category !== 'gridViewInitial';
          });

          if (nodes && nodes.length > 0) {
            diagram.model.removeNodeDataCollection(nodes);
            diagram.model.linkDataArray = [];

            const nodeKey =
              diagram.model.nodeDataArray.find((node) => !node.isGroup && node.category === 'treeViewInitial').key || 0;

            updateDiagramNodesData('clearDiagram', nodeKey);
          }

          handleUpdateWorkspaceData();
          handleCenterDiagram();
        }
      });
    }

    return;
  };

  const handleToggleExportDiagram = (isOpen) => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      diagram.nodes.each((n) => {
        const nodeLoader = n.findObject('nodeLoader');

        if (nodeLoader && !n.data.isGroup) {
          if (n.currentAnimation) n.currentAnimation.stop();
          nodeLoader.visible = isOpen;
          nodeLoader.source = isOpen ? '' : '/assets/images/spinner.svg';

          if (n.data.category === 'gridView') {
            nodeLoader.visible = false;
            nodeLoader.source = '';
          }
        }
      });

      setIsExportAllChecked(false);
      setIsExportDiagramOpen(isOpen);
      handleShowExportSubButton(false);

      if (!isOpen) handleUpdateActionNodes('add', actionNodes, actionNodes);
    }

    return;
  };

  const handleSelectAllForExport = (checked) => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      diagram.nodes.each((n) => {
        const nodeLoader = n.findObject('nodeLoader');

        if (nodeLoader && !n.data.isGroup && n.data.category === 'treeViewIdea') {
          if (n.currentAnimation) n.currentAnimation.stop();

          if (!checked) {
            nodeLoader.visible = true;
            nodeLoader.source = '';
          } else {
            nodeLoader.visible = false;
            nodeLoader.source = '/assets/images/spinner.svg';
          }
        }
      });

      setIsExportAllChecked(checked);
    }

    return;
  };

  const handleExportDiagramDocument = () => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      setIsLoadingExportDiagram(true);
      setLoaderMessage(loadingMessages?.export_report || null);

      const exportNodes = [];

      diagram.nodes.each((n) => {
        const nodeLoader = n.findObject('nodeLoader');

        if (nodeLoader && !n.data.isGroup) {
          if (n.data.category === 'treeViewIdea' && nodeLoader.visible === false) {
            exportNodes.push({
              main: n.data.data.title,
              body: n.data.data.description,
            });
          }

          if (n.currentAnimation) n.currentAnimation.stop();

          nodeLoader.visible = false;
          nodeLoader.source = '/assets/images/spinner.svg';
        }
      });

      if (exportNodes.length > 0) {
        generateExportDiagramDocument({
          ideas: exportNodes,
          title: workspaceTitle || '',
          workspaceId: workspaceData.work_space.id,
        })
          .then(async (response) => {
            const url = response.file_name;

            const fileName = decodeURIComponent(new URL(url).pathname.split('/').pop().replace(/\+/g, ' '));
            await axios
              .get(url, {
                responseType: 'blob',
              })
              .then((res) => {
                fileDownload(res.data, fileName);

                setIsExportDiagramOpen(false);
                setIsLoadingExportDiagram(false);
                setLoaderMessage(null);

                toast.success('File downloaded successfully.', TOAST_CONFIG);
              });
          })
          .catch((error) => {
            setIsExportDiagramOpen(false);
            setIsLoadingExportDiagram(false);
            setLoaderMessage(null);

            toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
          });
      } else {
        setIsExportDiagramOpen(false);
        setIsLoadingExportDiagram(false);
        setLoaderMessage(null);
      }
    }

    return;
  };

  const handleExportDiagramNodeSelection = (node) => {
    if (goDiagram.current && node) {
      const diagram = goDiagram.current.getDiagram();
      const nodeLoader = node.findObject('nodeLoader');

      if (nodeLoader && !node.data.isGroup && node.data.category === 'treeViewIdea') {
        console.log('nodeLoader', nodeLoader.visible);
        if (nodeLoader.visible) {
          nodeLoader.visible = false;
          nodeLoader.source = '/assets/images/spinner.svg';
        } else {
          nodeLoader.visible = true;
          nodeLoader.source = '';
        }

        const highlightedNode = diagram.nodes.filter((n) => {
          const loader = n.findObject('nodeLoader');
          // console.log(n.data);
          return !!(loader && !n.data.isGroup && n.data.category === 'treeViewIdea' && loader.visible);
        });

        setIsExportAllChecked(!!(highlightedNode && Object.keys(highlightedNode).length === 0));
      }
    }

    return;
  };

  // Handler for Init Diagram (Workspace Initialization)
  const handleInitDiagram = (workspace, isEditable, isAuthenticated) => {
    const $ = go.GraphObject.make;

    // Diagram Config
    const diagram = $(go.Diagram, {
      allowCopy: false,
      allowDelete: true,
      maxSelectionCount: 1,
      initialDocumentSpot: go.Spot.Top,
      initialViewportSpot: go.Spot.Top,
      initialContentAlignment: go.Spot.Top,
      validCycle: go.Diagram.CycleDestinationTree,
      scrollMode: go.Diagram.InfiniteScroll,
      padding: new go.Margin(150, 0, 0, 0),
      'commandHandler.zoomFactor': 1.1,
      'undoManager.isEnabled': true,
      'draggingTool.dragsTree': true,
      'draggingTool.dragsLink': true,
      'grid.visible': true,
      // 'toolManager.mouseWheelBehavior': go.ToolManager.WheelZoom,
      'toolManager.doMouseWheel': () => {
        var e = diagram.lastInput;
        var zoom = e.delta > 0 ? 'in' : 'out';
        let newScale = diagram.scale;

        if (zoom === 'in') newScale *= 1.2;
        else if (zoom === 'out') newScale /= 1.2;

        const animation = new go.Animation();
        animation.easing = go.Animation.EaseOutExpo;
        animation.duration = 900;

        animation.add(diagram, 'scale', diagram.scale, newScale);
        animation.start();
      },
      model: $(go.GraphLinksModel, {
        linkKeyProperty: 'key',
      }),
      layout: $(go.GridLayout, {
        wrappingWidth: Infinity,
        wrappingColumn:
          (JSON.parse(workspace.output) || []).filter((node) => node.category == 'gridView' && node.isGroup).length ||
          0,
        alignment: go.GridLayout.Position,
        cellSize: new go.Size(1, 1),
        spacing: new go.Size(50, 50),
        comparer(a, b) {
          var acat = a.data.category;
          var bcat = b.data.category;
          if (acat === 'gridView' && bcat !== 'gridView') return -1;
          if (acat !== 'gridView' && bcat === 'gridView') return 1;
          return 0;
        },
      }),
      ViewportBoundsChanged: (e) => {
        const { diagram } = e;
        const { scale } = diagram;
        const baseSize = 25;

        const newSize = baseSize / scale;
        diagram.grid.gridCellSize = new go.Size(newSize, newSize);

        diagram.grid.findObject('lineH1').strokeWidth = 0.5 / scale;
        diagram.grid.findObject('lineH2').strokeWidth = 0.5 / scale;
        diagram.grid.findObject('lineV1').strokeWidth = 0.5 / scale;
        diagram.grid.findObject('lineV2').strokeWidth = 0.5 / scale;
      },
    });

    diagram.toolManager.dragSelectingTool.isEnabled = false;

    // Handler For Generate Workspace Initial Nodes
    const handleGenerateInitialNodes = (diagram) => {
      if (!diagram) return;

      const { nodeDataArray } = diagram.model;

      if (nodeDataArray.length > 0) {
        nodeDataArray
          .filter((node) => node.category === 'gridViewInitial')
          .map((nodeData) => {
            const actualNode = nodeDataArray.filter((node) => node.name === nodeData.name);
            if (actualNode.length === 2) {
              const node = diagram.findNodeForKey(nodeData.key);

              handleToggleLoader(true, node);

              generateGroupNodesDataApi({
                workspace_id: workspace.id,
                group: node.data.name,
              })
                .then((response) => {
                  const generatedNodes = (response.data[0][node.data.name] || []).map((item) => ({
                    group: node.data.group,
                    category: 'gridView',
                    name: node.data.name,
                    data: { title: item.main, description: item.body },
                  }));

                  if (generatedNodes.length > 0) addNewNodes(node, generatedNodes);
                })
                .catch((error) => {
                  handleToggleLoader(false, node);
                  toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
                });
            }
          });

        const mindmapNode = nodeDataArray.find(
          (node) => node.category === 'treeViewInitial' && node.name === 'mindmap',
        );

        const actualTreeNodes = nodeDataArray.filter(
          (node) => node.category === 'treeViewIdea' && node.name === 'idea',
        );

        if (mindmapNode && actualTreeNodes.length === 0) {
          const node = diagram.findNodeForKey(mindmapNode.key);
          handleGenerateNodes('treeViewIdea', node);
        }
      }

      return;
    };

    // Handler For Generate Nodes
    const handleGenerateNodes = async (category, node) => {
      if (!isAuthenticated && isEditable) await handleRedirectToLogin();
      if (!node) return;

      toggleActionNode('add', [node.data.key]);
      handleToggleLoader(true, node);

      const data = [];

      switch (category) {
        case 'gridView':
          data.push({
            group: node.data.group,
            category: 'gridView',
            name: node.data.name,
            data: {
              title: `Your new ${node.data.name} title here`,
              description: 'Type description here...',
            },
          });
          break;

        case 'treeViewYourIdea':
          data.push({
            parent: node.data.key,
            group: node.data.group,
            category: 'treeViewYourIdea',
            name: 'custom',
            data: { title: 'Your idea title here', description: 'Type description here...' },
          });
          break;

        case 'treeViewMerged':
          const idea1 = [node.data.data.title];
          const idea2 = [node.data.second.title];

          for (let n = node; n !== null; n = n.findTreeParentNode()) {
            idea1.push(n.data.data.title);
            idea2.push(n.data.data.title);
          }

          await generateCombinedNodesDataApi({
            idea1,
            idea2,
            workspaceId: workspace.id,
          })
            .then((response) => {
              (response[0] || []).forEach((item) => {
                data.push({
                  parent: node.data.key,
                  group: node.data.group,
                  category: 'treeViewIdea',
                  name: 'idea',
                  data: { title: item.main, description: item.body },
                });
              });
            })
            .catch((error) => {
              toggleActionNode('remove', [node.data.key]);
              handleToggleLoader(false, node);

              toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
            });

          break;

        case 'treeViewIdea':
        case 'treeViewActionPlan':
        case 'treeViewMoreInfo':
          const context = [];

          for (let n = node; n !== null; n = n.findTreeParentNode()) {
            context.push({
              main: n.data.data.title,
              body: n.data.data.description,
            });
          }

          const prompt = context[0] || {};

          context.reverse();
          context.splice(-1);

          const ideas = [];

          diagram.nodes.each((n) => {
            if (
              n.data.key !== node.data.key &&
              n.data.parent === node.data.parent &&
              n.data.category === 'treeViewIdea'
            )
              ideas.push(n.data.data.title);
          });

          const generationType =
            category === 'treeViewIdea' ? 'IDEA' : category === 'treeViewActionPlan' ? 'EXAMPLE' : 'INFO';

          await generateMindmapNodesDataApi({
            prompt,
            context,
            ideas,
            generation_type: generationType,
            workspace_id: workspace.id,
          })
            .then((response) => {
              if (category === 'treeViewIdea') {
                (response[0] || []).forEach((item) => {
                  data.push({
                    parent: node.data.key,
                    group: node.data.group,
                    category: 'treeViewIdea',
                    name: 'idea',
                    data: { title: item.main, description: item.body },
                  });
                });
              } else if (category === 'treeViewActionPlan') {
                const actionSteps = (response[0] || []).map((item) => item.body);

                data.push({
                  parent: node.data.key,
                  group: node.data.group,
                  category: 'treeViewActionPlan',
                  name: 'example',
                  data: { title: 'Action Steps', list: actionSteps },
                });
              } else {
                const infoList = (response[0] || []).filter((item) => item !== '');

                data.push({
                  parent: node.data.key,
                  group: node.data.group,
                  category: 'treeViewMoreInfo',
                  name: 'info',
                  data: { title: 'More Information', list: infoList },
                });
              }
            })
            .catch((error) => {
              toggleActionNode('remove', [node.data.key]);
              handleToggleLoader(false, node);

              toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
            });

          break;

        default:
          toggleActionNode('remove', [node.data.key]);
          handleToggleLoader(false, node);
          break;
      }

      if (data.length > 0) await addNewNodes(node, data);
      return;
    };
    diagram.handleGenerateNodes = handleGenerateNodes;

    // Handler For Add New Nodes to Workspace
    const addNewNodes = async (node, data) => {
      handleToggleLoader(false, node);

      const { model } = diagram;
      model.startTransaction('Add New Nodes');

      data.forEach((nodeData) => {
        model.addNodeData(nodeData);

        if (nodeData.parent) {
          const newNode = diagram.findNodeForData(nodeData);

          model.addLinkData({ from: nodeData.parent, to: newNode.key });
        }
      });

      model.commitTransaction('Add New Nodes');

      // Rearrange Layout
      diagram.layoutDiagram(true);

      handleUpdateWorkspaceData();
      toggleActionNode('remove', [node.data.key]);
      updateDiagramNodesData('create', node.data.key, data);

      return;
    };
    // Handler For Toggle Loader
    const handleToggleLoader = (isShow, node) => {
      if (!node) return;
      var loader = node.findObject('nodeLoader');
      loader.visible = isShow;

      if (isShow) {
        var animation = new go.Animation();
        animation.add(loader, 'angle', 0, 360);
        animation.runCount = Infinity; // Loop indefinitely
        animation.start();

        node.currentAnimation = animation;
      } else if (node.currentAnimation) {
        node.currentAnimation.stop();
        node.currentAnimation = null;

        diagram.layout.invalidateLayout();
        diagram.layoutDiagram(true);
      }
    };

    const handleOpenTextBlockEditor = (event, object) => {
      if (object.editable) {
        toggleActionNode('add', [object.part.data.key]);

        diagram.commandHandler.editTextBlock(object);
      }

      return;
    };

    // Handler For Text Edited
    const handleTextEdited = (object, oldString, newString) => {
      const node = object.part;
      const { diagram } = node;
      const { model } = diagram;

      const propertyName = object.name.replace('node', '').toLowerCase();

      const updatedData = {
        ...node.data,
        data: {
          ...node.data.data,
          [propertyName]: newString,
        },
      };

      model.startTransaction('updateNodeData');
      model.assignAllDataProperties(node.data, updatedData);
      model.updateTargetBindings(node.data);
      model.commitTransaction('updateNodeData');

      handleUpdateWorkspaceData();
      updateDiagramNodesData('update', node.data.key, updatedData);
      toggleActionNode('remove', [node.data.key]);

      return;
    };

    // Handler For Show Delete Button
    const handleShowDeleteButton = (event, object) => {
      const nodeDeleteButton = object.part.findObject('nodeDeleteButton');
      if (nodeDeleteButton) nodeDeleteButton.visible = true;
    };

    // Handler For Hide Delete Button
    const handleHideDeleteButton = (event, node) => {
      const nodeDeleteButton = node.findObject('nodeDeleteButton');
      if (nodeDeleteButton && nodeDeleteButton.visible) nodeDeleteButton.visible = false;
    };

    // Handler For Delete Node
    const handleDeleteNode = async (event, object) => {
      if (!isAuthenticated && isEditable) await handleRedirectToLogin();
      const node = object.part;

      if (node.findTreeChildrenNodes().count <= 0) {
        toggleActionNode('add', [node.data.key]);
        handleToggleLoader(true, node);

        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.isConfirmed) {
            diagram.remove(node);

            handleUpdateWorkspaceData();
            updateDiagramNodesData('delete', node.data.key);
          } else {
            handleToggleLoader(false, node);
          }

          toggleActionNode('remove', [node.data.key]);
        });
      }
    };
    diagram.handleDeleteNode = handleDeleteNode;

    // Handler For Collapse Nodes
    const handleCollapseNodes = (event, object) => {
      const node = object.part;
      const isCollapse = node?.data?.isCollapse ? node.data.isCollapse : false;
      diagram.model.setDataProperty(node.data, 'isCollapse', !isCollapse);

      node.findTreeChildrenNodes().each((childNode) => {
        nodeCollapseOrExpand(childNode, isCollapse);
      });

      // if (!isCollapse) {
      //   const hideChildNodes = (parentNode) => {
      //     parentNode.findTreeChildrenNodes().each((childNode) => {
      //       diagram.model.setDataProperty(childNode.data, 'visible', false);

      //       if (childNode.findTreeChildrenNodes().count > 0)
      //         diagram.model.setDataProperty(childNode.data, 'isCollapse', true);

      //       hideChildNodes(childNode);
      //     });
      //   };

      //   hideChildNodes(node);
      // } else {
      //   node.findTreeChildrenNodes().each((childNode) => {
      //     diagram.model.setDataProperty(childNode.data, 'visible', true);
      //   });
      // }
    };

    const nodeCollapseOrExpand = (node, visible) => {
      diagram.startTransaction('Node Collapse/Expand');

      var animation = new go.Animation();
      animation.isViewportUnchanged = true;
      animation.duration = 600;

      if (visible) animation.add(node, 'scale', 0.1, 1);
      else animation.add(node, 'scale', 1, 1);

      diagram.model.setDataProperty(node.data, 'visible', visible);

      const onAnimationFinished = (e) => {
        diagram.layout.invalidateLayout();
        diagram.layoutDiagram(true);

        diagram.removeDiagramListener('AnimationFinished', onAnimationFinished);
      };

      diagram.addDiagramListener('AnimationFinished', onAnimationFinished);

      animation.start();
      diagram.commitTransaction('Node Collapse/Expand');

      if (!visible && node.findTreeChildrenNodes().count > 0) {
        diagram.model.setDataProperty(node.data, 'isCollapse', true);

        node.findTreeChildrenNodes().each((childNode) => {
          nodeCollapseOrExpand(childNode, false);
        });
      }
    };

    // Handler For Toggle Actions Menu
    const handleToggleActionsMenu = (event, object) => {
      const node = object.part;

      const actionButton = node.findObject('nodeMoreActionsMenuButton');
      const actionsMenu = node.findObject('nodeMoreActionsMenu');

      if (actionsMenu.height !== 0 && actionsMenu.height !== 200) return;

      // Button Animation
      var animation = new go.Animation();
      animation.easing = go.Animation.EaseOutExpo;
      animation.duration = 500;

      if (actionsMenu.height === 0) {
        animation.add(actionButton, 'angle', 0, 45);
      } else {
        animation.add(actionButton, 'angle', 45, 0);
      }

      animation.start();

      // Action Menu Animation
      var animation = new go.Animation();
      animation.easing = go.Animation.EaseOutExpo;
      animation.duration = 500;

      if (actionsMenu.height === 0) {
        animation.add(actionsMenu, 'height', 0, 200);
      } else {
        animation.add(actionsMenu, 'height', 200, 0);
      }

      animation.start();
    };

    // Handler For Open Node More Actions Modal
    const handleOpenNodeMoreActionsModal = async (event, object, action) => {
      if (!isAuthenticated && isEditable) await handleRedirectToLogin();

      const node = object.part;
      const { position } = diagram;

      const button = node.findObject('nodeDescriptionCollapseButton');
      const description = node.findObject('nodeDescription');

      if (button !== null && description !== null) {
        description.maxSize = new go.Size(325, NaN);
        button.text = 'Show less';
      }

      diagram.scale = 1;
      diagram.commandHandler.scrollToPart(node);

      const offset = window.innerWidth * 0.25;
      diagram.position = new go.Point(position.x + offset, position.y);

      const actionsMenu = node.findObject('nodeMoreActionsMenu');
      if (actionsMenu && actionsMenu.height > 0) handleToggleActionsMenu(event, object);

      handleToggleMoreActionsModal(object.part, action);
    };

    // Handler For Action Plan Mouse Enter
    const handleActionPlanMouseEnter = (e, object) => {
      const button1 = object.part.findObject('generateIdeaButton');
      const button2 = object.part.findObject('generateActionPlanButton');

      button1.margin = new go.Margin(3, 0, 0, 0);

      const animation1 = new go.Animation();
      animation1.easing = go.Animation.EaseOutExpo;
      animation1.duration = 500;
      animation1.add(button1, 'width', 92, 0);
      animation1.add(button1, 'opacity', 1, 0);
      animation1.start();

      button2.margin = new go.Margin(3, 0, 0, 10);

      const animation2 = new go.Animation();
      animation2.easing = go.Animation.EaseOutExpo;
      animation2.duration = 500;
      animation2.add(button2, 'width', 0, 135);
      animation2.add(button2, 'opacity', 0, 1);
      animation2.start();

      return;
    };

    // Handler For Action Plan Mouse Leave
    const handleActionPlanMouseLeave = (e, object) => {
      const button1 = object.part.findObject('generateIdeaButton');
      const button2 = object.part.findObject('generateActionPlanButton');

      button2.margin = new go.Margin(3, 0, 0, 0);

      const animation1 = new go.Animation();
      animation1.easing = go.Animation.EaseOutExpo;
      animation1.duration = 500;
      animation1.add(button2, 'width', 135, 0);
      animation1.add(button2, 'opacity', 1, 0);
      animation1.start();

      button1.margin = new go.Margin(3, 0, 0, 10);

      const animation2 = new go.Animation();
      animation2.easing = go.Animation.EaseOutExpo;
      animation2.duration = 500;
      animation2.add(button1, 'width', 0, 92);
      animation2.add(button1, 'opacity', 0, 1);
      animation2.start();

      return;
    };

    // Rounded Top Rectangle Shape
    go.Shape.defineFigureGenerator('RoundedTopRectangle', (shape, w, h) => {
      var geo = new go.Geometry();
      var fig = new go.PathFigure(0, 11.2, true);
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Arc, 180, 90, 11.2, 11.2, 11.2, 11.2));
      fig.add(new go.PathSegment(go.PathSegment.Line, w - 11.2, 0));
      fig.add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - 11.2, 11.2, 11.2, 11.2));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 11.2).close());
      return geo;
    });

    // Diagram Make Unique Key Function
    diagram.model.makeUniqueKeyFunction = (model, data) => {
      let key = 1;
      while (model.findNodeDataForKey(key) !== null) key++;
      return key;
    };

    // Background Grid Template
    diagram.grid = $(
      go.Panel,
      'Grid',
      { gridCellSize: new go.Size(25, 25) },
      $(go.Shape, 'LineH', { stroke: 'rgba(240, 84, 54, 0.02)', strokeWidth: 0.5, interval: 1, name: 'lineH1' }),
      $(go.Shape, 'LineH', { stroke: 'rgba(240, 84, 54, 0.20)', strokeWidth: 0.5, interval: 4, name: 'lineH2' }),
      $(go.Shape, 'LineV', { stroke: 'rgba(240, 84, 54, 0.02)', strokeWidth: 0.5, interval: 1, name: 'lineV1' }),
      $(go.Shape, 'LineV', { stroke: 'rgba(240, 84, 54, 0.20)', strokeWidth: 0.5, interval: 4, name: 'lineV2' }),
    );

    // Tree View Group Template
    diagram.groupTemplateMap.add(
      'treeView',
      $(go.Group, 'Auto', {
        layout: $(go.TreeLayout, {
          treeStyle: go.TreeLayout.StyleLastParents,
          arrangement: go.TreeLayout.ArrangementHorizontal,
          angle: 90,
          layerSpacing: 35,
          alternateAngle: 90,
          alternateLayerSpacing: 35,
          alternateNodeSpacing: 20,
          alternatePortSpot: new go.Spot(0.5, 1, 0, 0),
          alternateChildPortSpot: go.Spot.Top,
          nodeSpacing: 100,
        }),
        height: 'auto',
        width: 'auto',
      }),
    );

    // Grid View Group Template
    diagram.groupTemplateMap.add(
      'gridView',
      $(
        go.Group,
        'Auto',
        {
          layout: $(go.GridLayout, {
            wrappingColumn: 3,
            spacing: new go.Size(20, 20),
            alignment: go.GridLayout.Position,
          }),
          height: 'auto',
          width: 'auto',
          movable: false,
          selectionAdornmentTemplate: $(go.Adornment),
        },
        $(
          go.Panel,
          'Auto',
          $(go.Shape, 'RoundedRectangle', {
            fill: 'transparent',
            stroke: '#F05436',
            strokeWidth: 2,
            shadowVisible: true,
          }),
          $(
            go.Panel,
            'Vertical',
            { margin: 5, padding: new go.Margin(5, 5, 5, 5) },
            $(
              go.TextBlock,
              {
                margin: new go.Margin(0, 0, 10, 0),
                font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                stroke: '#F05436',
                alignment: go.Spot.TopLeft,
                wrap: go.TextBlock.WrapFit,
                overflow: go.TextBlock.OverflowEllipsis,
              },
              new go.Binding('text', 'data', (data, obj) => {
                return data.title || '';
              }),
            ),
            $(go.Placeholder, { padding: 10 }),
          ),
        ),
      ),
    );

    // Tree View Initial Node Template
    diagram.nodeTemplateMap.add(
      'treeViewInitial',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(40, 5, 0, 5),
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');

            return nodeLoader.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');

              return nodeLoader.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');

              return nodeLoader.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeMoreActionsMenu'),

            new go.Binding('pickable', '', (data, object) => {
              const nodeLoader = object.part.findObject('nodeLoader');
              const nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0);
            }).ofObject('nodeLoader'),

            new go.Binding('pickable', '', (data, object) => {
              const nodeLoader = object.part.findObject('nodeLoader');
              const nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0);
            }).ofObject('nodeMoreActionsMenu'),

            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(45, 20, 0, 20),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 220),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                    },
                    new go.Binding('text', 'data', (data) => {
                      return data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),
                ),
              ),

              $(
                go.Panel,
                'Horizontal',
                {
                  alignment: go.Spot.Bottom,
                  defaultAlignment: go.Spot.Left,
                  stretch: go.GraphObject.Horizontal,
                  margin: new go.Margin(10, 0, 15, 0),
                  visible: isEditable,
                },
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 15, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewIdea', object.part),
                  },

                  $(go.Shape, 'RoundedRectangle', {
                    minSize: new go.Size(35, 35),
                    parameter1: 8,
                    fill: '#F05436',
                    strokeWidth: 1,
                    stroke: '#F05436',
                  }),

                  $(
                    go.Panel,
                    'Horizontal',
                    {
                      alignment: go.Spot.Center,
                      padding: new go.Margin(0, 5, 0, 5),
                    },
                    $(go.Picture, {
                      source: '/assets/images/idea_v2.svg',
                      desiredSize: new go.Size(16.5, 22),
                    }),
                    $(go.TextBlock, 'Generate Idea', {
                      name: 'generateIdeaButton',
                      margin: new go.Margin(3, 0, 0, 10),
                      desiredSize: new go.Size(92, 13),
                      stroke: 'white',
                      font: 'bold 9pt poppins-semibold, Helvetica, Arial, sans-serif',
                    }),
                  ),
                ),

                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 15, 0, 0),
                    cursor: 'pointer',
                    click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Action Plan'),
                    mouseEnter: handleActionPlanMouseEnter,
                    mouseLeave: handleActionPlanMouseLeave,
                  },

                  $(go.Shape, 'RoundedRectangle', {
                    minSize: new go.Size(35, 35),
                    parameter1: 8,
                    fill: '#F05436',
                    strokeWidth: 1,
                    stroke: '#F05436',
                  }),

                  $(
                    go.Panel,
                    'Horizontal',
                    {
                      alignment: go.Spot.Center,
                      padding: new go.Margin(0, 4, 0, 4),
                    },
                    $(go.Picture, {
                      source: '/assets/images/action_plan.svg',
                      desiredSize: new go.Size(18, 22),
                    }),
                    $(go.TextBlock, 'Generate Action Plan', {
                      name: 'generateActionPlanButton',
                      margin: new go.Margin(3, 0, 0, 0),
                      desiredSize: new go.Size(0, 13),
                      stroke: 'white',
                      font: 'bold 9pt poppins-semibold, Helvetica, Arial, sans-serif',
                    }),
                  ),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),
        ),

        $(
          go.Panel,
          'Auto',
          {
            name: 'nodeMoreActionsMenuButtonWrapper',
            alignment: new go.Spot(1, 1, -25, -23),
            alignmentFocus: go.Spot.BottomRight,
            margin: new go.Margin(0, 0, 0, 0),
            cursor: 'pointer',
            visible: isEditable,
            click: handleToggleActionsMenu,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.4 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091D',
            parameter1: 8,
            strokeWidth: 1,
            stroke: '#F05436',
            desiredSize: new go.Size(35, 35),
          }),

          $(go.Picture, {
            name: 'nodeMoreActionsMenuButton',
            source: '/assets/images/add-new.png',
            desiredSize: new go.Size(20, 20),
          }),
        ),

        $(
          go.Panel,
          'Spot',
          {
            alignment: go.Spot.Top,
          },
          $(go.Shape, 'Circle', {
            width: 50,
            shadowVisible: true,
            strokeWidth: 0,
            fill: '#09091D',
          }),
          $(go.Picture, {
            name: 'nodeIcon',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/goal.svg',
          }),
        ),

        $(
          go.Panel,
          'Position',
          {
            name: 'nodeMoreActionsMenu',
            height: 0,
            alignment: new go.Spot(1, 1, -26, -65),
            alignmentFocus: go.Spot.BottomRight,
          },
          new go.Binding('visible', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091d',
            strokeWidth: 0,
            parameter1: 10,
            height: 200,
            width: 160,
            shadowVisible: true,
          }),

          $(
            go.Panel,
            'Table',
            {
              height: 200,
              width: 160,
            },

            $(
              go.Panel,
              'Auto',
              {
                row: 0,
                width: 160,
                column: 0,
                columnSpan: 3,
              },
              $(go.Shape, 'RoundedTopRectangle', {
                fill: '#F05436',
                strokeWidth: 0,
                parameter1: 10,
                spot1: new go.Spot(0, 0, 0, 0),
                spot2: new go.Spot(1, 1, 0, 0),
              }),
              $(go.TextBlock, 'More Actions', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                textAlign: 'left',
                stroke: '#fff',
                margin: new go.Margin(10, 15, 7, -47),
              }),
            ), // More Actions Label

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 1,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(8, 0, 0, 0),
              },
              $(go.TextBlock, 'Generate', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Generate Label

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 2,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 1, 2.5, 10),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Action Plan'),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(10, 10),
                  margin: new go.Margin(1.5, 0, 0, 0),
                  source: '/assets/images/action_plan.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 17),
                },
                $(go.TextBlock, 'Action Plan', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Action Plan Button

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 3,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(4.5, 0, 0, -0.1),
                padding: new go.Margin(0, 0, 0, 0),
                height: 5,
              },

              $(go.Shape, 'LineH', {
                stroke: '#c2c2c2',
                strokeWidth: 0.5,
                alignment: go.Spot.Bottom,
                margin: new go.Margin(0, 0, 0, 0),
              }),
            ), // Divider

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 4,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(5, 0, 0, 0),
              },
              $(go.TextBlock, 'Add', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Add Label

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 5,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 1, 2.5, 10),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (e, object) => handleGenerateNodes('treeViewYourIdea', object.part),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(10, 10),
                  margin: new go.Margin(1.5, 0, 0, 0),
                  source: '/assets/images/add-orange.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 17),
                },
                $(go.TextBlock, 'Your Idea', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Your Idea Button

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 6,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 0, 2.5, 8),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Comments'),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(15, 15),
                  margin: new go.Margin(-1, 0, 0, 0),
                  source: '/assets/images/comment.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 19),
                },
                $(go.TextBlock, 'Comments', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Comments Button

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 7,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(4.5, 0, 0, -0.1),
                padding: new go.Margin(0, 0, 0, 0),
                height: 5,
              },

              $(go.Shape, 'LineH', {
                stroke: '#c2c2c2',
                strokeWidth: 0.5,
                alignment: go.Spot.Bottom,
                margin: new go.Margin(0, 0, 0, 0),
              }),
            ), // Divider

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 8,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(6, 0, 0, 0),
              },
              $(go.TextBlock, 'Edit', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Edit Label

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 9,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(1.5, 0, 2.5, 10),
                margin: new go.Margin(5, 0, 5.5, 0),
                cursor: 'pointer',
                click: handleDeleteNode,
                pickable: false,
                opacity: 0.5,
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  name: 'ideaButtonPicture',
                  desiredSize: new go.Size(12, 12),
                  source: '/assets/images/bin.svg',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1.5, 0, 0, 17),
                },
                $(go.TextBlock, 'Delete', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#F05436',
                }),
              ),
            ), // Delete Button
          ),
        ),

        $(
          go.Panel,
          'Spot',
          {
            click: handleCollapseNodes,
            name: 'nodeCollapseButtonWrapper',
            portId: 'nodeToggleButtonPort',
            cursor: 'pointer',
            alignment: go.Spot.Bottom,
            fromSpot: go.Spot.Bottom,
          },
          new go.Binding('visible', '', (node) => {
            const children = diagram.findNodeForKey(node.data.key).findTreeChildrenNodes();
            return children && children.count > 0;
          }).ofObject(),

          $(
            go.Panel,
            'Auto',
            {
              margin: new go.Margin(70, 0, 0, 0),
            },
            $(go.Shape, {
              figure: 'Rectangle',
              fill: null,
              strokeWidth: 0,
            }),
            $(
              go.Picture,
              {
                name: 'nodeCollapseButton',
                margin: new go.Margin(4, 4, 8, 4),
              },
              new go.Binding('source', '', (data) => {
                const isCollapse = data.isCollapse !== undefined ? data.isCollapse : false;
                return isCollapse ? '/assets/images/show.svg' : '/assets/images/hide.svg';
              }),
            ),
          ),
        ),
      ),
    );

    // Tree View Idea
    diagram.nodeTemplateMap.add(
      'treeViewIdea',
      $(
        go.Node,
        'Spot',
        new go.Binding('visible'),
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
          click: handleHideDeleteButton,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(40, 5, 0, 5),
            contextClick: handleShowDeleteButton,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),

          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          }).ofObject('nodeDeleteButton'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeMoreActionsMenu'),

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeDeleteButton'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0) && !nodeDeleteButton.visible;
            }).ofObject('nodeLoader'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0) && !nodeDeleteButton.visible;
            }).ofObject('nodeMoreActionsMenu'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0) && !nodeDeleteButton.visible;
            }).ofObject('nodeDeleteButton'),

            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(45, 20, 0, 20),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 160),
                    margin: new go.Margin(0, 0, 60, 0),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                    },
                    new go.Binding('text', 'data', (data) => {
                      return data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      spacingBelow: 1.5,
                      visible: true,
                      name: 'nodeDescription',
                      row: 2,
                      column: 0,
                      columnSpan: 2,
                      stroke: 'white',
                      font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, 92),
                      margin: new go.Margin(15, 0, 0, 0),
                      stretch: go.GraphObject.Fill,
                      overflow: go.TextBlock.OverflowEllipsis,
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.description;
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeDescriptionCollapseButton',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: '9pt poppins, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      stroke: '#F05436',
                      margin: new go.Margin(10, 0, 0, 0),
                      isUnderline: true,
                      cursor: 'pointer',
                      text: 'Show more',
                      textAlign: 'center',
                      click: (e, object) => {
                        const node = object.part;
                        if (!node) return;

                        const button = node.findObject('nodeDescriptionCollapseButton');
                        const description = node.findObject('nodeDescription');

                        if (button.text === 'Show more') {
                          description.maxSize = new go.Size(325, NaN);
                          button.text = 'Show less';
                        } else {
                          description.maxSize = new go.Size(325, 92);
                          button.text = 'Show more';
                        }
                      },
                    },
                    new go.Binding('visible', 'data', (data) => {
                      if (data.description) {
                        const offscreenDiv = document.createElement('div');
                        offscreenDiv.style.position = 'absolute';
                        offscreenDiv.style.visibility = 'hidden';
                        offscreenDiv.style.width = '325px';
                        offscreenDiv.style.font = '9pt poppinsregular, Helvetica, Arial, sans-serif';
                        offscreenDiv.style.lineHeight = '1.25';
                        offscreenDiv.style.whiteSpace = 'normal';
                        offscreenDiv.style.overflow = 'hidden';
                        offscreenDiv.innerText = data.description;

                        document.body.appendChild(offscreenDiv);
                        const height = offscreenDiv.clientHeight;
                        document.body.removeChild(offscreenDiv);

                        return height > 92;
                      }
                      return false;
                    }),
                  ),
                ),
              ),

              $(
                go.Panel,
                'Horizontal',
                {
                  alignment: go.Spot.Bottom,
                  defaultAlignment: go.Spot.Left,
                  stretch: go.GraphObject.Horizontal,
                  margin: new go.Margin(10, 0, 15, 0),
                  visible: isEditable,
                },
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 15, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewIdea', object.part),
                  },

                  $(go.Shape, 'RoundedRectangle', {
                    minSize: new go.Size(35, 35),
                    parameter1: 8,
                    fill: '#F05436',
                    strokeWidth: 1,
                    stroke: '#F05436',
                  }),

                  $(
                    go.Panel,
                    'Horizontal',
                    {
                      alignment: go.Spot.Center,
                      padding: new go.Margin(0, 5, 0, 5),
                    },
                    $(go.Picture, {
                      source: '/assets/images/idea_v2.svg',
                      desiredSize: new go.Size(16.5, 22),
                    }),
                    $(go.TextBlock, 'Generate Idea', {
                      name: 'generateIdeaButton',
                      margin: new go.Margin(3, 0, 0, 10),
                      desiredSize: new go.Size(92, 13),
                      stroke: 'white',
                      font: 'bold 9pt poppins-semibold, Helvetica, Arial, sans-serif',
                    }),
                  ),
                ),

                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 15, 0, 0),
                    cursor: 'pointer',
                    click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Action Plan'),
                    mouseEnter: handleActionPlanMouseEnter,
                    mouseLeave: handleActionPlanMouseLeave,
                  },

                  $(go.Shape, 'RoundedRectangle', {
                    minSize: new go.Size(35, 35),
                    parameter1: 8,
                    fill: '#F05436',
                    strokeWidth: 1,
                    stroke: '#F05436',
                  }),

                  $(
                    go.Panel,
                    'Horizontal',
                    {
                      alignment: go.Spot.Center,
                      padding: new go.Margin(0, 4, 0, 4),
                    },
                    $(go.Picture, {
                      source: '/assets/images/action_plan.svg',
                      desiredSize: new go.Size(18, 22),
                    }),
                    $(go.TextBlock, 'Generate Action Plan', {
                      name: 'generateActionPlanButton',
                      margin: new go.Margin(3, 0, 0, 0),
                      desiredSize: new go.Size(0, 13),
                      stroke: 'white',
                      font: 'bold 9pt poppins-semibold, Helvetica, Arial, sans-serif',
                    }),
                  ),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),

          $(
            go.Panel,
            'Auto',
            {
              visible: false,
              name: 'nodeDeleteButton',
              alignment: go.Spot.Center,
              cursor: 'pointer',
              click: handleDeleteNode,
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return !nodeLoader.visible;
            }).ofObject('nodeLoader'),

            $(go.Shape, 'RoundedRectangle', {
              fill: '#09091D',
              stroke: '#F05436',
              strokeWidth: 1,
              height: 35,
              width: 100,
            }),

            $(
              go.Panel,
              'Horizontal',
              {
                alignment: go.Spot.Center,
              },
              $(go.Picture, {
                source: '/assets/images/bin.svg',
                desiredSize: new go.Size(20, 20),
                margin: new go.Margin(0, 5, 0, 0),
              }),
              $(go.TextBlock, 'Delete', {
                margin: new go.Margin(5, 0, 0, 5),
                stroke: '#F05436',
                font: 'bold 12pt sans-serif',
              }),
            ),
          ),
        ),

        $(
          go.Panel,
          'Auto',
          {
            name: 'nodeMoreActionsMenuButtonWrapper',
            alignment: new go.Spot(1, 1, -25, -23),
            alignmentFocus: go.Spot.BottomRight,
            margin: new go.Margin(0, 0, 0, 0),
            cursor: 'pointer',
            visible: isEditable,
            click: handleToggleActionsMenu,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
          }).ofObject('nodeLoader'),

          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
          }).ofObject('nodeDeleteButton'),

          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return !nodeLoader.visible && !nodeDeleteButton.visible;
          }).ofObject('nodeLoader'),

          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return !nodeLoader.visible && !nodeDeleteButton.visible;
          }).ofObject('nodeDeleteButton'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091D',
            parameter1: 8,
            strokeWidth: 1,
            stroke: '#F05436',
            desiredSize: new go.Size(35, 35),
          }),

          $(go.Picture, {
            name: 'nodeMoreActionsMenuButton',
            source: '/assets/images/add-new.png',
            desiredSize: new go.Size(20, 20),
          }),
        ),

        $(
          go.Panel,
          'Spot',
          {
            alignment: go.Spot.Top,
          },
          $(go.Shape, 'Circle', {
            width: 50,
            shadowVisible: true,
            strokeWidth: 0,
            fill: '#09091D',
          }),
          $(go.Picture, {
            name: 'nodeIcon',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/idea.svg',
          }),
        ),

        $(
          go.Panel,
          'Position',
          {
            name: 'nodeMoreActionsMenu',
            height: 0,
            alignment: new go.Spot(1, 1, -26, -65),
            alignmentFocus: go.Spot.BottomRight,
          },
          new go.Binding('visible', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091d',
            strokeWidth: 0,
            parameter1: 10,
            height: 200,
            width: 160,
            shadowVisible: true,
          }),

          $(
            go.Panel,
            'Table',
            {
              height: 200,
              width: 160,
            },

            $(
              go.Panel,
              'Auto',
              {
                row: 0,
                width: 160,
                column: 0,
                columnSpan: 3,
              },
              $(go.Shape, 'RoundedTopRectangle', {
                fill: '#F05436',
                strokeWidth: 0,
                parameter1: 10,
                spot1: new go.Spot(0, 0, 0, 0),
                spot2: new go.Spot(1, 1, 0, 0),
              }),
              $(go.TextBlock, 'More Actions', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                textAlign: 'left',
                stroke: '#fff',
                margin: new go.Margin(10, 15, 7, -47),
              }),
            ), // More Actions Label

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 1,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(8, 0, 0, 0),
              },
              $(go.TextBlock, 'Generate', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Generate Label

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 2,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 1, 2.5, 10),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Action Plan'),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(10, 10),
                  margin: new go.Margin(1.5, 0, 0, 0),
                  source: '/assets/images/action_plan.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 17),
                },
                $(go.TextBlock, 'Action Plan', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Action Plan Button

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 3,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(4.5, 0, 0, -0.1),
                padding: new go.Margin(0, 0, 0, 0),
                height: 5,
              },

              $(go.Shape, 'LineH', {
                stroke: '#c2c2c2',
                strokeWidth: 0.5,
                alignment: go.Spot.Bottom,
                margin: new go.Margin(0, 0, 0, 0),
              }),
            ), // Divider

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 4,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(5, 0, 0, 0),
              },
              $(go.TextBlock, 'Add', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Add Label

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 5,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 1, 2.5, 10),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (e, object) => handleGenerateNodes('treeViewYourIdea', object.part),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(10, 10),
                  margin: new go.Margin(1.5, 0, 0, 0),
                  source: '/assets/images/add-orange.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 17),
                },
                $(go.TextBlock, 'Your Idea', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Your Idea Button

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 6,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 0, 2.5, 8),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Comments'),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(15, 15),
                  margin: new go.Margin(-1, 0, 0, 0),
                  source: '/assets/images/comment.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 19),
                },
                $(go.TextBlock, 'Comments', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Comments Button

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 7,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(4.5, 0, 0, -0.1),
                padding: new go.Margin(0, 0, 0, 0),
                height: 5,
              },

              $(go.Shape, 'LineH', {
                stroke: '#c2c2c2',
                strokeWidth: 0.5,
                alignment: go.Spot.Bottom,
                margin: new go.Margin(0, 0, 0, 0),
              }),
            ), // Divider

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 8,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(6, 0, 0, 0),
              },
              $(go.TextBlock, 'Edit', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Edit Label

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 9,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(1.5, 0, 2.5, 10),
                margin: new go.Margin(5, 0, 5.5, 0),
                cursor: 'pointer',
                click: handleDeleteNode,
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  name: 'ideaButtonPicture',
                  desiredSize: new go.Size(12, 12),
                  source: '/assets/images/bin.svg',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1.5, 0, 0, 17),
                },
                $(go.TextBlock, 'Delete', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#F05436',
                }),
              ),
            ), // Delete Button
          ),
        ),

        $(
          go.Panel,
          'Spot',
          {
            click: handleCollapseNodes,
            name: 'nodeCollapseButtonWrapper',
            portId: 'nodeToggleButtonPort',
            cursor: 'pointer',
            alignment: go.Spot.Bottom,
            fromSpot: go.Spot.Bottom,
          },
          new go.Binding('visible', '', (node) => {
            const children = diagram.findNodeForKey(node.data.key).findTreeChildrenNodes();
            return children && children.count > 0;
          }).ofObject(),

          $(
            go.Panel,
            'Auto',
            {
              margin: new go.Margin(70, 0, 0, 0),
            },
            $(go.Shape, {
              figure: 'Rectangle',
              fill: null,
              strokeWidth: 0,
            }),
            $(
              go.Picture,
              {
                name: 'nodeCollapseButton',
                margin: new go.Margin(4, 4, 8, 4),
              },
              new go.Binding('source', '', (data) => {
                const isCollapse = data.isCollapse !== undefined ? data.isCollapse : false;
                return isCollapse ? '/assets/images/show.svg' : '/assets/images/hide.svg';
              }),
            ),
          ),
        ),
      ),
    );

    // Tree View Action Plan
    diagram.nodeTemplateMap.add(
      'treeViewActionPlan',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Tree View More Info
    diagram.nodeTemplateMap.add(
      'treeViewMoreInfo',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Tree View Your Idea
    diagram.nodeTemplateMap.add(
      'treeViewYourIdea',
      $(
        go.Node,
        'Spot',
        new go.Binding('visible'),
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
          click: handleHideDeleteButton,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(40, 5, 0, 5),
            contextClick: handleShowDeleteButton,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),

          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          }).ofObject('nodeDeleteButton'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');

              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeMoreActionsMenu'),

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : nodeMoreActionsMenu.height > 0 ? 0.1 : 1;
            }).ofObject('nodeDeleteButton'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0) && !nodeDeleteButton.visible;
            }).ofObject('nodeLoader'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0) && !nodeDeleteButton.visible;
            }).ofObject('nodeMoreActionsMenu'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeMoreActionsMenu = object.part.findObject('nodeMoreActionsMenu');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

              return !nodeLoader.visible && !(nodeMoreActionsMenu.height > 0) && !nodeDeleteButton.visible;
            }).ofObject('nodeDeleteButton'),

            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(45, 20, 0, 20),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    minSize: new go.Size(NaN, 160),
                    margin: new go.Margin(0, 0, 60, 0),
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      click: handleOpenTextBlockEditor,
                      textEdited: handleTextEdited,
                    },
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: isEditable && isAuthenticated,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                      cursor: 'pointer',
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      click: handleOpenTextBlockEditor,
                      textEdited: handleTextEdited,
                    },
                    {
                      spacingBelow: 1.5,
                      visible: true,
                      name: 'nodeDescription',
                      row: 2,
                      column: 0,
                      columnSpan: 2,
                      stroke: 'white',
                      font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                      editable: isEditable && isAuthenticated,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, 92),
                      margin: new go.Margin(15, 0, 0, 0),
                      stretch: go.GraphObject.Fill,
                      overflow: go.TextBlock.OverflowEllipsis,
                      cursor: 'pointer',
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.description;
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeDescriptionCollapseButton',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: '9pt poppins, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      stroke: '#F05436',
                      margin: new go.Margin(10, 0, 0, 0),
                      isUnderline: true,
                      cursor: 'pointer',
                      text: 'Show more',
                      textAlign: 'center',
                      click: (e, object) => {
                        const node = object.part;
                        if (!node) return;

                        const button = node.findObject('nodeDescriptionCollapseButton');
                        const description = node.findObject('nodeDescription');

                        if (button.text === 'Show more') {
                          description.maxSize = new go.Size(325, NaN);
                          button.text = 'Show less';
                        } else {
                          description.maxSize = new go.Size(325, 92);
                          button.text = 'Show more';
                        }
                      },
                    },
                    new go.Binding('visible', 'data', (data) => {
                      if (data.description) {
                        const offscreenDiv = document.createElement('div');
                        offscreenDiv.style.position = 'absolute';
                        offscreenDiv.style.visibility = 'hidden';
                        offscreenDiv.style.width = '325px';
                        offscreenDiv.style.font = '9pt poppinsregular, Helvetica, Arial, sans-serif';
                        offscreenDiv.style.lineHeight = '1.25';
                        offscreenDiv.style.whiteSpace = 'normal';
                        offscreenDiv.style.overflow = 'hidden';
                        offscreenDiv.innerText = data.description;

                        document.body.appendChild(offscreenDiv);
                        const height = offscreenDiv.clientHeight;
                        document.body.removeChild(offscreenDiv);

                        return height > 92;
                      }
                      return false;
                    }),
                  ),
                ),
              ),

              $(
                go.Panel,
                'Horizontal',
                {
                  alignment: go.Spot.Bottom,
                  defaultAlignment: go.Spot.Left,
                  stretch: go.GraphObject.Horizontal,
                  margin: new go.Margin(10, 0, 15, 0),
                  visible: isEditable,
                },
                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 15, 0, 0),
                    cursor: 'pointer',
                    click: (e, object) => handleGenerateNodes('treeViewIdea', object.part),
                  },

                  $(go.Shape, 'RoundedRectangle', {
                    minSize: new go.Size(35, 35),
                    parameter1: 8,
                    fill: '#F05436',
                    strokeWidth: 1,
                    stroke: '#F05436',
                  }),

                  $(
                    go.Panel,
                    'Horizontal',
                    {
                      alignment: go.Spot.Center,
                      padding: new go.Margin(0, 5, 0, 5),
                    },
                    $(go.Picture, {
                      source: '/assets/images/idea_v2.svg',
                      desiredSize: new go.Size(16.5, 22),
                    }),
                    $(go.TextBlock, 'Generate Idea', {
                      name: 'generateIdeaButton',
                      margin: new go.Margin(3, 0, 0, 10),
                      desiredSize: new go.Size(92, 13),
                      stroke: 'white',
                      font: 'bold 9pt poppins-semibold, Helvetica, Arial, sans-serif',
                    }),
                  ),
                ),

                $(
                  go.Panel,
                  'Auto',
                  {
                    alignment: go.Spot.Left,
                    margin: new go.Margin(0, 15, 0, 0),
                    cursor: 'pointer',
                    click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Action Plan'),
                    mouseEnter: handleActionPlanMouseEnter,
                    mouseLeave: handleActionPlanMouseLeave,
                  },

                  $(go.Shape, 'RoundedRectangle', {
                    minSize: new go.Size(35, 35),
                    parameter1: 8,
                    fill: '#F05436',
                    strokeWidth: 1,
                    stroke: '#F05436',
                  }),

                  $(
                    go.Panel,
                    'Horizontal',
                    {
                      alignment: go.Spot.Center,
                      padding: new go.Margin(0, 4, 0, 4),
                    },
                    $(go.Picture, {
                      source: '/assets/images/action_plan.svg',
                      desiredSize: new go.Size(18, 22),
                    }),
                    $(go.TextBlock, 'Generate Action Plan', {
                      name: 'generateActionPlanButton',
                      margin: new go.Margin(3, 0, 0, 0),
                      desiredSize: new go.Size(0, 13),
                      stroke: 'white',
                      font: 'bold 9pt poppins-semibold, Helvetica, Arial, sans-serif',
                    }),
                  ),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),

          $(
            go.Panel,
            'Auto',
            {
              visible: false,
              name: 'nodeDeleteButton',
              alignment: go.Spot.Center,
              cursor: 'pointer',
              click: handleDeleteNode,
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return !nodeLoader.visible;
            }).ofObject('nodeLoader'),

            $(go.Shape, 'RoundedRectangle', {
              fill: '#09091D',
              stroke: '#F05436',
              strokeWidth: 1,
              height: 35,
              width: 100,
            }),

            $(
              go.Panel,
              'Horizontal',
              {
                alignment: go.Spot.Center,
              },
              $(go.Picture, {
                source: '/assets/images/bin.svg',
                desiredSize: new go.Size(20, 20),
                margin: new go.Margin(0, 5, 0, 0),
              }),
              $(go.TextBlock, 'Delete', {
                margin: new go.Margin(5, 0, 0, 5),
                stroke: '#F05436',
                font: 'bold 12pt sans-serif',
              }),
            ),
          ),
        ),

        $(
          go.Panel,
          'Auto',
          {
            name: 'nodeMoreActionsMenuButtonWrapper',
            alignment: new go.Spot(1, 1, -25, -23),
            alignmentFocus: go.Spot.BottomRight,
            margin: new go.Margin(0, 0, 0, 0),
            cursor: 'pointer',
            visible: isEditable,
            click: handleToggleActionsMenu,
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
          }).ofObject('nodeLoader'),

          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
          }).ofObject('nodeDeleteButton'),

          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return !nodeLoader.visible && !nodeDeleteButton.visible;
          }).ofObject('nodeLoader'),

          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');

            return !nodeLoader.visible && !nodeDeleteButton.visible;
          }).ofObject('nodeDeleteButton'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091D',
            parameter1: 8,
            strokeWidth: 1,
            stroke: '#F05436',
            desiredSize: new go.Size(35, 35),
          }),

          $(go.Picture, {
            name: 'nodeMoreActionsMenuButton',
            source: '/assets/images/add-new.png',
            desiredSize: new go.Size(20, 20),
          }),
        ),

        $(
          go.Panel,
          'Spot',
          {
            alignment: go.Spot.Top,
          },
          $(go.Shape, 'Circle', {
            width: 50,
            shadowVisible: true,
            strokeWidth: 0,
            fill: '#09091D',
          }),
          $(go.Picture, {
            name: 'nodeIcon',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/add-orange.svg',
          }),
        ),

        $(
          go.Panel,
          'Position',
          {
            name: 'nodeMoreActionsMenu',
            height: 0,
            alignment: new go.Spot(1, 1, -26, -65),
            alignmentFocus: go.Spot.BottomRight,
          },
          new go.Binding('visible', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            fill: '#09091d',
            strokeWidth: 0,
            parameter1: 10,
            height: 200,
            width: 160,
            shadowVisible: true,
          }),

          $(
            go.Panel,
            'Table',
            {
              height: 200,
              width: 160,
            },

            $(
              go.Panel,
              'Auto',
              {
                row: 0,
                width: 160,
                column: 0,
                columnSpan: 3,
              },
              $(go.Shape, 'RoundedTopRectangle', {
                fill: '#F05436',
                strokeWidth: 0,
                parameter1: 10,
                spot1: new go.Spot(0, 0, 0, 0),
                spot2: new go.Spot(1, 1, 0, 0),
              }),
              $(go.TextBlock, 'More Actions', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                textAlign: 'left',
                stroke: '#fff',
                margin: new go.Margin(10, 15, 7, -47),
              }),
            ), // More Actions Label

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 1,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(8, 0, 0, 0),
              },
              $(go.TextBlock, 'Generate', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Generate Label

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 2,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 1, 2.5, 10),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Action Plan'),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(10, 10),
                  margin: new go.Margin(1.5, 0, 0, 0),
                  source: '/assets/images/action_plan.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 17),
                },
                $(go.TextBlock, 'Action Plan', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Action Plan Button

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 3,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(4.5, 0, 0, -0.1),
                padding: new go.Margin(0, 0, 0, 0),
                height: 5,
              },

              $(go.Shape, 'LineH', {
                stroke: '#c2c2c2',
                strokeWidth: 0.5,
                alignment: go.Spot.Bottom,
                margin: new go.Margin(0, 0, 0, 0),
              }),
            ), // Divider

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 4,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(5, 0, 0, 0),
              },
              $(go.TextBlock, 'Add', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Add Label

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 5,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 1, 2.5, 10),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (e, object) => handleGenerateNodes('treeViewYourIdea', object.part),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(10, 10),
                  margin: new go.Margin(1.5, 0, 0, 0),
                  source: '/assets/images/add-orange.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 17),
                },
                $(go.TextBlock, 'Your Idea', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Your Idea Button

            $(
              go.Panel,
              'Auto',
              {
                mouseEnter: (e, obj) => {
                  obj.background = '#F05436';
                },
                mouseLeave: (e, obj) => {
                  obj.background = 'transparent';
                },
                width: 160,
                row: 6,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(3, 0, 2.5, 8),
                margin: new go.Margin(5, 0, 0, 0),
                cursor: 'pointer',
                click: (event, object) => handleOpenNodeMoreActionsModal(event, object, 'Comments'),
                background: 'transparent',
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  desiredSize: new go.Size(15, 15),
                  margin: new go.Margin(-1, 0, 0, 0),
                  source: '/assets/images/comment.png',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1, 0, 0, 19),
                },
                $(go.TextBlock, 'Comments', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#fff',
                }),
              ),
            ), // Comments Button

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 7,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(4.5, 0, 0, -0.1),
                padding: new go.Margin(0, 0, 0, 0),
                height: 5,
              },

              $(go.Shape, 'LineH', {
                stroke: '#c2c2c2',
                strokeWidth: 0.5,
                alignment: go.Spot.Bottom,
                margin: new go.Margin(0, 0, 0, 0),
              }),
            ), // Divider

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 8,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(0, 0, 0, 10),
                margin: new go.Margin(6, 0, 0, 0),
              },
              $(go.TextBlock, 'Edit', {
                font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#c2c2c2',
              }),
            ), // Edit Label

            $(
              go.Panel,
              'Auto',
              {
                width: 160,
                row: 9,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                padding: new go.Margin(1.5, 0, 2.5, 10),
                margin: new go.Margin(5, 0, 5.5, 0),
                cursor: 'pointer',
                click: handleDeleteNode,
              },

              $(
                go.Panel,
                'Auto',
                { alignment: go.Spot.Left },
                $(go.Picture, {
                  name: 'ideaButtonPicture',
                  desiredSize: new go.Size(12, 12),
                  source: '/assets/images/bin.svg',
                }),
              ),

              $(
                go.Panel,
                'Auto',
                {
                  alignment: go.Spot.Left,
                  padding: new go.Margin(1.5, 0, 0, 17),
                },
                $(go.TextBlock, 'Delete', {
                  font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                  stroke: '#F05436',
                }),
              ),
            ), // Delete Button
          ),
        ),

        $(
          go.Panel,
          'Spot',
          {
            click: handleCollapseNodes,
            name: 'nodeCollapseButtonWrapper',
            portId: 'nodeToggleButtonPort',
            cursor: 'pointer',
            alignment: go.Spot.Bottom,
            fromSpot: go.Spot.Bottom,
          },
          new go.Binding('visible', '', (node) => {
            const children = diagram.findNodeForKey(node.data.key).findTreeChildrenNodes();
            return children && children.count > 0;
          }).ofObject(),

          $(
            go.Panel,
            'Auto',
            {
              margin: new go.Margin(70, 0, 0, 0),
            },
            $(go.Shape, {
              figure: 'Rectangle',
              fill: null,
              strokeWidth: 0,
            }),
            $(
              go.Picture,
              {
                name: 'nodeCollapseButton',
                margin: new go.Margin(4, 4, 8, 4),
              },
              new go.Binding('source', '', (data) => {
                const isCollapse = data.isCollapse !== undefined ? data.isCollapse : false;
                return isCollapse ? '/assets/images/show.svg' : '/assets/images/hide.svg';
              }),
            ),
          ),
        ),
      ),
    );

    // Tree View Merged Node
    diagram.nodeTemplateMap.add(
      'treeViewMerged',
      $(go.Node, 'Spot', {
        selectionObjectName: 'nodeWrapper',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
      }),
    );

    // Grid View Initial Node Template
    diagram.nodeTemplateMap.add(
      'gridViewInitial',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
          visible: isEditable,
          movable: false,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(10, 10, 10, 10),
          },
          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return nodeLoader.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),
          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },
            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0.4 : 1;
            }).ofObject('nodeLoader'),
            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(0, 20, 0, 20),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  name: 'textPanelMain',
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: false,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                      margin: new go.Margin(15, 0, 0, 0),
                    },
                    new go.Binding('text', 'data', (data) => {
                      return data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.Panel,
                    'Spot',
                    {
                      margin: new go.Margin(13, 13, 13, 13),
                      width: 80,
                      height: 80,
                      cursor: 'pointer',
                      click: (e, object) => handleGenerateNodes('gridView', object.part),
                      // isShadowed: true,
                      // shadowVisible: true,
                      // shadowOffset: new go.Point(0, 2),
                      // shadowColor: 'rgba(255,255,255,0.9)',
                      // shadowBlur: 6,
                    },
                    // $(go.Shape, { strokeWidth: 0, fill: 'rgba(255,255,255,0.9)' }),
                    $(go.Shape, 'Circle', {
                      fill: '#F05436',
                      desiredSize: new go.Size(80, 80),
                    }),
                    $(go.Picture, {
                      name: 'nodeIcon',
                      desiredSize: new go.Size(20, 20),
                      source: '/assets/images/add.png',
                      alignment: go.Spot.Center,
                      margin: 1,
                    }),
                  ),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),
        ),
      ),
    );

    // Grid View Node Template
    diagram.nodeTemplateMap.add(
      'gridView',
      $(
        go.Node,
        'Spot',
        {
          selectionObjectName: 'nodeWrapper',
          isShadowed: true,
          shadowOffset: new go.Point(0, 0),
          shadowColor: 'white',
          shadowBlur: 13,
          click: handleHideDeleteButton,
          movable: false,
        },

        {
          selectionAdornmentTemplate: $(
            go.Adornment,
            'Auto',
            $(go.Shape, 'RoundedRectangle', {
              fill: null,
              strokeWidth: 0,
              parameter1: 15,
              stroke: '#F05436',
            }),
            $(go.Placeholder),
          ),
        },

        $(
          go.Panel,
          'Auto',
          {
            shadowVisible: true,
            name: 'nodeWrapper',
            margin: new go.Margin(10, 10, 10, 10),
            contextClick: handleShowDeleteButton,
            cursor: 'pointer',
          },

          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          }).ofObject('nodeLoader'),

          new go.Binding('opacity', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
            return nodeLoader.visible || nodeDeleteButton.visible ? 0.9 : 1;
          }).ofObject('nodeDeleteButton'),

          new go.Binding('pickable', '', (data, object) => {
            var nodeLoader = object.part.findObject('nodeLoader');
            return !nodeLoader.visible;
          }).ofObject('nodeLoader'),

          $(go.Shape, 'RoundedRectangle', {
            name: 'nodeWrapperShape',
            parameter1: 15,
            fill: '#09091D',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          }),

          $(
            go.Panel,
            'Vertical',
            {
              name: 'nodeContent',
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
              return nodeLoader.visible || nodeDeleteButton.visible ? 0.4 : 1;
            }).ofObject('nodeDeleteButton'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeDeleteButton = object.part.findObject('nodeDeleteButton');
              return !nodeDeleteButton.visible;
            }).ofObject('nodeDeleteButton'),

            $(
              go.Panel,
              'Table',
              {
                minSize: new go.Size(325, NaN),
                maxSize: new go.Size(325, NaN),
                margin: new go.Margin(20, 20, 20, 20),
                defaultAlignment: go.Spot.Left,
              },

              $(
                go.Panel,
                'Horizontal',
                {
                  minSize: new go.Size(NaN, 98),
                  name: 'textPanelMain',
                  alignment: go.Spot.Center,
                },
                $(
                  go.Panel,
                  'Vertical',
                  {
                    name: 'textPanel',
                  },

                  $(
                    go.TextBlock,
                    {
                      click: handleOpenTextBlockEditor,
                      textEdited: handleTextEdited,
                    },
                    {
                      name: 'nodeTitle',
                      row: 0,
                      column: 0,
                      columnSpan: 2,
                      font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                      wrap: go.TextBlock.WrapFit,
                      editable: isEditable && isAuthenticated,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      textAlign: 'center',
                      stroke: '#F05436',
                      overflow: go.TextBlock.OverflowEllipsis,
                      cursor: 'pointer',
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.title.toUpperCase();
                    }).makeTwoWay(),
                  ),

                  $(
                    go.TextBlock,
                    {
                      click: handleOpenTextBlockEditor,
                      textEdited: handleTextEdited,
                    },
                    {
                      spacingBelow: 1.5,
                      visible: false,
                      name: 'nodeDescription',
                      row: 2,
                      column: 0,
                      columnSpan: 2,
                      stroke: 'white',
                      font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                      editable: isEditable && isAuthenticated,
                      minSize: new go.Size(325, NaN),
                      maxSize: new go.Size(325, NaN),
                      margin: new go.Margin(15, 0, 0, 0),
                      stretch: go.GraphObject.Fill,
                      overflow: go.TextBlock.OverflowEllipsis,
                      cursor: 'pointer',
                    },
                    new go.Binding('text', '', (data) => {
                      return data.data.description;
                    }).makeTwoWay(),
                  ),

                  $(go.TextBlock, {
                    name: 'nodeDescriptionCollapseButton',
                    row: 0,
                    column: 0,
                    columnSpan: 2,
                    font: '9pt poppins, Helvetica, Arial, sans-serif',
                    wrap: go.TextBlock.WrapFit,
                    editable: false,
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, NaN),
                    stroke: '#F05436',
                    margin: new go.Margin(10, 0, 0, 0),
                    isUnderline: true,
                    cursor: 'pointer',
                    text: 'Show more',
                    textAlign: 'center',
                    click: (e, object) => {
                      const node = object.part;
                      if (!node) return;

                      const button = node.findObject('nodeDescriptionCollapseButton');
                      const description = node.findObject('nodeDescription');

                      if (button.text === 'Show more') {
                        description.visible = true;
                        button.text = 'Show less';
                      } else {
                        description.visible = false;
                        button.text = 'Show more';
                      }
                    },
                  }),
                ),
              ),
            ),
          ),

          $(go.Picture, {
            visible: false,
            name: 'nodeLoader',
            desiredSize: new go.Size(60, 60),
            source: '/assets/images/spinner.svg',
          }),

          $(
            go.Panel,
            'Auto',
            {
              visible: false,
              name: 'nodeDeleteButton',
              alignment: go.Spot.Center,
              cursor: 'pointer',
              click: handleDeleteNode,
            },

            new go.Binding('opacity', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return nodeLoader.visible ? 0 : 1;
            }).ofObject('nodeLoader'),

            new go.Binding('pickable', '', (data, object) => {
              var nodeLoader = object.part.findObject('nodeLoader');
              return !nodeLoader.visible;
            }).ofObject('nodeLoader'),

            $(go.Shape, 'RoundedRectangle', {
              fill: '#09091D',
              stroke: '#F05436',
              strokeWidth: 1,
              height: 35,
              width: 100,
            }),

            $(
              go.Panel,
              'Horizontal',
              {
                alignment: go.Spot.Center,
              },
              $(go.Picture, {
                source: '/assets/images/bin.svg',
                desiredSize: new go.Size(20, 20),
                margin: new go.Margin(0, 5, 0, 0),
              }),
              $(go.TextBlock, 'Delete', {
                margin: new go.Margin(5, 0, 0, 5),
                stroke: '#F05436',
                font: 'bold 12pt sans-serif',
              }),
            ),
          ),
        ),
      ),
    );

    // Link Template
    diagram.linkTemplate = $(
      go.Link,
      go.Link.Orthogonal,
      { layerName: 'Background', corner: 5, selectable: false },
      $(
        go.Shape,
        new go.Binding('stroke', 'isHighlighted', (h) => {
          return h ? '#F05436' : '#F05436';
        }).ofObject(),
        {
          strokeWidth: 7,
          stroke: '#F05436',
        },
      ),
      {
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Bottom,
        fromPortId: 'nodeToggleButtonPort',
      },
    );

    // Diagram Initial Layout Completed Listener
    diagram.addDiagramListener('InitialLayoutCompleted', (e) => {
      const { diagram } = e;

      const firstNode = diagram.model.nodeDataArray.find(
        (node) => node.category === 'treeViewInitial' && node.name === 'mindmap',
      );

      if (firstNode) {
        diagram.scale = 1;
        diagram.commandHandler.scrollToPart(diagram.findNodeForKey(firstNode.key));
      }

      // Generate initial nodes
      if (isAuthenticated && isEditable && (!workspace.status || workspace.status === 0))
        handleGenerateInitialNodes(diagram);
    });

    // Diagram Object Single Clicked Listener
    diagram.addDiagramListener('ObjectSingleClicked', (e) => {
      var { part, name } = e.subject;

      if (!['nodeMoreActionsMenuButtonWrapper', 'nodeMoreActionsMenu'].includes(name) && part) {
        const actionsMenu = part.findObject('nodeMoreActionsMenu');
        if (actionsMenu && actionsMenu.height > 0) handleToggleActionsMenu(e, e.subject);
      }
    });

    return diagram;
  };

  // Socket 'updateActionNodes' Event Handlers
  const handleUpdateActionNodes = (action, nodes = [], activeNodes = []) => {
    if (socket.current && goDiagram.current) {
      const socketId = socket.current.id;
      const diagram = goDiagram.current.getDiagram();

      setActionNodes(activeNodes.filter((node) => node.socketId != socketId));

      nodes.forEach((nodeData) => {
        if (nodeData.socketId !== socketId) {
          const node = diagram.findNodeForKey(nodeData.nodeId);
          if (node) {
            const nodeLoader = node.findObject('nodeLoader');
            if (nodeLoader) {
              nodeLoader.source = '/assets/images/spinner.svg';
              nodeLoader.visible = action === 'add';

              if (action === 'add') {
                var animation = new go.Animation();
                animation.add(nodeLoader, 'angle', 0, 360);
                animation.runCount = Infinity;
                animation.start();
              } else if (node.currentAnimation) {
                node.currentAnimation.stop();
              }
            }
          }
        }
      });
    }

    return;
  };

  // Toggle Socket Action Nodes
  const toggleActionNode = (action, nodesData = []) => {
    if (socket.current && workspaceData?.work_space?.id) {
      const socketClient = socket.current;

      const nodes = nodesData.map((nodeId) => ({
        socketId: socketClient.id,
        nodeId,
      }));

      if (nodes.length > 0) socketClient.emit('updateActionNodes', action, nodes, workspaceData?.work_space?.id);
    }

    return;
  };

  // Update Diagram Nodes Data
  const updateDiagramNodesData = (action, node, nodesData = null) => {
    if (socket.current && workspaceData?.work_space?.id) {
      const socketClient = socket.current;

      socketClient.emit('updateDiagramNodes', action, node, workspaceData?.work_space?.id, nodesData);
    }

    return;
  };

  // Socket 'updateDiagramNodes' Event Handlers
  const handleUpdateDiagramNodes = (action, nodeKey, nodesData = null) => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();
      const { model } = diagram;
      const node = diagram.findNodeForKey(nodeKey);

      if (node) {
        switch (action) {
          case 'create':
            model.startTransaction('Add New Nodes');

            nodesData.forEach((nodeData) => {
              model.addNodeData(nodeData);

              if (nodeData.parent) {
                const newNode = diagram.findNodeForData(nodeData);

                model.addLinkData({ from: nodeData.parent, to: newNode.key });
              }
            });

            model.commitTransaction('Add New Nodes');

            // Rearrange Layout
            diagram.layoutDiagram(true);

            break;

          case 'update':
            model.startTransaction('updateNodeData');
            model.assignAllDataProperties(node.data, nodesData);
            model.updateTargetBindings(node.data);
            model.commitTransaction('updateNodeData');

            break;

          case 'delete':
            diagram.remove(node);
            break;

          case 'clearDiagram':
            var nodes = diagram.model.nodeDataArray.filter((node) => {
              return !node.isGroup && node.category !== 'treeViewInitial' && node.category !== 'gridViewInitial';
            });

            if (nodes && nodes.length > 0) {
              diagram.model.removeNodeDataCollection(nodes);
              diagram.model.linkDataArray = [];
            }

            handleCenterDiagram();
            break;
          default:
            break;
        }
      }
    }

    return;
  };

  // Handler for generate your idea node from more actions menu
  const handleGenerateYourIdeaNode = async () => {
    if (goDiagram.current && nodeMoreActionsData?.node_id) {
      const diagram = goDiagram.current.getDiagram();
      const node = diagram.findNodeForKey(nodeMoreActionsData?.node_id);

      await handleToggleMoreActionsModal();

      diagram.handleGenerateNodes('treeViewYourIdea', node);
    }

    return;
  };

  // Handler for delete node data from more actions menu
  const handleDeleteNodeData = async () => {
    if (goDiagram.current && nodeMoreActionsData?.node_id) {
      const diagram = goDiagram.current.getDiagram();
      const node = diagram.findNodeForKey(nodeMoreActionsData?.node_id);
      const nodeDeleteButton = node.findObject('nodeDeleteButton');

      await handleToggleMoreActionsModal();

      if (nodeDeleteButton) diagram.handleDeleteNode(null, nodeDeleteButton);
    }

    return;
  };

  // Handle Show Export Sub Button
  const handleShowExportSubButton = (value) => {
    setIsExportSubButtonOpen(value);
  };

  // Diagram Event Listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreActionsModalRef.current && !moreActionsModalRef.current.contains(event.target)) {
        if (nodeMoreActionsData !== null) {
          handleToggleMoreActionsModal();
        }
      }

      if (
        isExportDiagramOpen === true &&
        exportButtonsRef.current &&
        !exportButtonsRef.current.contains(event.target) &&
        goDiagramWrapperRef.current &&
        !goDiagramWrapperRef.current.contains(event.target) &&
        spinnerRef.current &&
        !spinnerRef.current.contains(event.target)
      )
        handleToggleExportDiagram(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExportDiagramOpen, nodeMoreActionsData]);

  // Export Workspace Data Related Event Listener
  useEffect(() => {
    if (goDiagram.current) {
      const diagram = goDiagram.current.getDiagram();

      const handleObjectClick = (e) => {
        if (isExportDiagramOpen) {
          const { part } = e.subject;

          if (part instanceof go.Node) {
            handleExportDiagramNodeSelection(part);
          }
        }
      };

      const handleBackgroundClick = (e) => {
        if (isExportDiagramOpen) handleToggleExportDiagram(false);
      };

      diagram.addDiagramListener('ObjectSingleClicked', handleObjectClick);
      diagram.addDiagramListener('BackgroundSingleClicked', handleBackgroundClick);

      return () => {
        diagram.removeDiagramListener('ObjectSingleClicked', handleObjectClick);
        diagram.removeDiagramListener('BackgroundSingleClicked', handleBackgroundClick);
      };
    }
  }, [isExportDiagramOpen, handleToggleExportDiagram]);

  // Socket Connection & Event Listener
  useEffect(() => {
    if (workspaceData?.work_space?.id) {
      // New WebSocket connection
      const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
        query: {
          roomId: workspaceData?.work_space?.id,
          user: {
            name: userData?.name || 'Unauthorized User',
            email: userData?.email || '',
          },
        },
      });
      socket.current = newSocket;

      // Handler for get Updated Action Nodes
      newSocket.on('updateActionNodes', handleUpdateActionNodes);

      // Handler for Update Diagram Nodes
      newSocket.on('updateDiagramNodes', handleUpdateDiagramNodes);

      // Cleanup function to leave the socket room
      return () => {
        newSocket.disconnect();
        socket.current = null;
      };
    }
  }, [workspaceData]);

  // Disable Zoom In/Out
  useEffect(() => {
    const preventZoom = (e) => {
      if (e.ctrlKey === true || (e.touches && e.touches.length > 1)) {
        e.preventDefault();
      }
    };

    document.addEventListener('wheel', preventZoom, { passive: false });
    document.addEventListener('touchmove', preventZoom, { passive: false });

    return () => {
      document.removeEventListener('wheel', preventZoom);
      document.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  return (
    <div className="workspace_edit-container">
      {(isLoadingGetWorkspaceDetails || isLoadingExportDiagram || isFetchingLoadingMessages) && (
        <div ref={spinnerRef} className="workspace_edit-spinner-wrapper">
          <Spinner animation="border" className="workspace_edit-spinner" />

          {loaderMessage && (
            <div className="workspace_edit-spinner-message">
              {loaderMessage.split('\n').map((msg, index) => (
                <p key={index}>{msg}</p>
              ))}
            </div>
          )}
        </div>
      )}

      <TopNavigationBar
        wrapperClass="workspace_edit-top-navigation-bar"
        isShowCompanyDropdown={false}
        logoLink={routes.dashBoard}
        isShowTrialAlert={!!(authToken && authToken !== '')}
        isShowToggleDropdown={!!(authToken && authToken !== '')}
        isShowProcessingAlert={!!(authToken && authToken !== '')}
        processingAlertText={isLoading ? 'Saving...' : 'Saved'}
      />

      <div className="workspace_edit-workspace-title">
        {isTitleEditing && authToken && authToken !== '' && workspaceData?.role === 'editor' ? (
          <input
            type="text"
            placeholder="Enter Workspace Title"
            defaultValue={workspaceTitle?.current}
            onBlur={handleBlurWorkspaceTitle}
            autoFocus
          />
        ) : (
          <h1 onClick={handleSetWorkspaceTitleEditing}>{workspaceTitle?.current}</h1>
        )}
      </div>

      {isExportDiagramOpen && (
        <div className="workspace_edit-workspace-export-title">
          <h3>Select the ideas you want to export</h3>
        </div>
      )}

      {workspaceData && workspaceData?.work_space?.output && (
        <div ref={goDiagramWrapperRef} className="workspace_edit-diagram-wrapper">
          <ReactDiagram
            ref={goDiagram}
            initDiagram={() =>
              handleInitDiagram(
                workspaceData.work_space,
                workspaceData.role === 'editor',
                authToken && authToken !== null,
              )
            }
            divClassName="workspace_edit-diagram"
            nodeDataArray={JSON.parse(workspaceData.work_space.output) || []}
            linkDataArray={(JSON.parse(workspaceData.work_space.output) || []).reduce((links, node) => {
              if (node.parent) {
                links.push({ from: node.parent, to: node.key });
              }
              return links;
            }, [])}
            skipsDiagramUpdate
          />
        </div>
      )}

      {nodeMoreActionsData && (
        <div ref={moreActionsModalRef} className="more_actions_modal-container">
          <div className="more_actions_modal-sidebar">
            <h4>More actions</h4>

            <div className="more_actions_modal-sidebar-menu">
              <span>Generate</span>

              <p
                onClick={() => handleUpdateNodeMoreActions('Action Plan')}
                className={`${nodeMoreActionsData?.action === 'Action Plan' && 'active'}`}
              >
                <img height={14} width={14} src="/assets/images/action_plan.png" alt="" />
                Action plan
              </p>

              <hr />

              <span>Add</span>

              <p onClick={handleGenerateYourIdeaNode}>
                <img height={14} width={14} src="/assets/images/add-orange.png" alt="" />
                Your idea
              </p>

              <p
                onClick={() => handleUpdateNodeMoreActions('Comments')}
                className={`${nodeMoreActionsData?.action === 'Comments' && 'active'}`}
                style={{
                  marginLeft: '-3px',
                  gap: '7px',
                }}
              >
                <img height={20} width={20} src="/assets/images/comment.png" alt="" />
                Comments
              </p>

              <hr />

              <span>Edit</span>

              <p onClick={handleDeleteNodeData}>
                <img height={14} width={14} src="/assets/images/bin.svg" alt="" />
                Delete
              </p>
            </div>

            <button
              onClick={() => handleToggleMoreActionsModal()}
              className="more_actions_modal-close-button"
              type="button"
            >
              <RiCloseLine color="#f05436" size="18" fontWeight="bold" />
              Close
            </button>
          </div>

          <div className="more_actions_modal-detailed-view">
            {nodeMoreActionsData?.action && nodeMoreActionsData?.action === 'Action Plan' ? (
              <ActionPlan nodeMoreActionsData={nodeMoreActionsData} goDiagram={goDiagram} />
            ) : nodeMoreActionsData?.action && nodeMoreActionsData?.action === 'Comments' ? (
              <Comments nodeMoreActionsData={nodeMoreActionsData} />
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      {!isExportDiagramOpen && (
        <div className="export_button_wrapper">
          <button type="button" onClick={() => handleShowExportSubButton(!isExportSubButtonOpen)}>
            <img height={22} src="/assets/images/download.png" alt="" /> Export
          </button>
          <div className={`export_sub_button_wrapper ${isExportSubButtonOpen ? 'open' : ''}`}>
            <OverlayTrigger placement="left" overlay={<Tooltip>Export PNG</Tooltip>}>
              <span onClick={handleExportDiagramImage}>
                <img src="/assets/images/picture.png" alt="" /> Image (PNG)
              </span>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={<Tooltip>Export PDF</Tooltip>}>
              <span
                onClick={() => {
                  if (actionNodes.length > 0) return;
                  handleToggleExportDiagram(true);
                }}
              >
                <img src="/assets/images/pdf.png" alt="" /> File (PDF)
              </span>
            </OverlayTrigger>
          </div>
        </div>
      )}

      {!isExportDiagramOpen && nodeMoreActionsData === null && (
        <div className="workspace_edit-abm-wrapper">
          <div
            className={`workspace_edit-abm-buttons ${
              isActionButtonMenuOpen
                ? authToken && authToken !== '' && workspaceData?.role === 'editor'
                  ? 'expanded'
                  : 'expanded-half'
                : ''
            }`}
          >
            <OverlayTrigger placement="left" overlay={<Tooltip>Zoom in</Tooltip>}>
              <span onClick={() => handleZoomInOutDiagram('in')}>
                <MdZoomIn />
              </span>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={<Tooltip>Zoom out</Tooltip>}>
              <span onClick={() => handleZoomInOutDiagram('out')}>
                <MdZoomOut />
              </span>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={<Tooltip>Fit to screen</Tooltip>}>
              <span onClick={handleDiagramFitToScreen}>
                <MdFitScreen />
              </span>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={<Tooltip>Center</Tooltip>}>
              <span onClick={handleCenterDiagram}>
                <MdFullscreenExit />
              </span>
            </OverlayTrigger>

            {authToken && authToken !== '' && workspaceData?.role === 'editor' && (
              <>
                <OverlayTrigger placement="left" overlay={<Tooltip>Clear all</Tooltip>}>
                  <span onClick={handleClearDiagram}>
                    <MdBackspace />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger placement="left" overlay={<Tooltip>Save</Tooltip>}>
                  <span
                    onClick={() => {
                      if (actionNodes.length > 0) return;
                      handleUpdateWorkspaceData();
                    }}
                  >
                    <MdSave />
                  </span>
                </OverlayTrigger>
              </>
            )}
          </div>

          <div className="workspace_edit-abm-toggle" onClick={handleToggleActionButtonMenu}>
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animationData: actionButtonMenuAnimation,
              }}
              style={{ margin: 'auto' }}
              height={40}
              width={45}
              direction={actionButtonMenuDirection}
            />
          </div>
        </div>
      )}

      {isExportDiagramOpen && (
        <div ref={exportButtonsRef} className="workspace_edit-workspace-export-buttons">
          <div className="export-checkbox" onClick={() => handleSelectAllForExport(!isExportAllChecked)}>
            {isExportAllChecked ? <FaCheckSquare className="checked" /> : <FaRegSquare className="unchecked" />}

            <span>Select All</span>
          </div>

          <button type="button" className="export-button" onClick={handleExportDiagramDocument}>
            Export <MdDownload />
          </button>
        </div>
      )}
    </div>
  );
};

export default WorkspaceEdit;
