import React from 'react';
import FounderStory from 'fetures/Home/Components/FounderStory';
import GetStartedWithIdeonToday from 'components/GetStartedWithIdeonToday';
import { AboutUs, SuccessIsMission } from './Components';

import './style.css';

const index = () => {
  return (
    <div className="width_about_get_start_container justify-content-center m-auto pb-5">
      {/* <AboutUs />

      <SuccessIsMission />

      <div className="width_about_get_start_container justify-content-center m-auto">
        <GetStartedWithIdeonToday />
      </div> */}

      <FounderStory />
    </div>
  );
};

export default index;
