import React, { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import moment from 'moment';
import PropTypes from 'prop-types';

import './style.css';

const CommentCard = ({ comment }) => {
  const [isCollapse, setIsCollapse] = useState(true);

  return (
    <div className="maap-comment-card-container">
      <div onClick={() => setIsCollapse(!isCollapse)} className="maap-comment-card">
        <div className="maap-comment-card-header">
          <p>
            {`${comment?.user_id?.first_name} ${comment?.user_id?.last_name}`} -{' '}
            {moment(comment.createdAt).format('DD MMM YYYY, HH:mm')}
          </p>

          {isCollapse ? <FiPlus color="#f05436" size="22" /> : <FiMinus color="#f05436" size="22" />}
        </div>

        {!isCollapse && (
          <div className="maap-comment-card-description">
            <h5>Description :</h5>
            <p>{comment.comment || ''}</p>
          </div>
        )}
      </div>
    </div>
  );
};

CommentCard.defaultProps = {
  comment: PropTypes.object,
};

CommentCard.propTypes = {
  comment: PropTypes.object,
};
export default CommentCard;
