import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constant';
// eslint-disable-next-line import/order
import { Field, Form, Formik } from 'formik';
import { LandingPageValidation } from 'pages/HomePage/constant';
import mainVid from 'assets/video_consultants_v2.mp4';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

const Banner = () => {
  const navigate = useNavigate();
  const redirectToSignUp = async (values) => {
    // console.log('redirectToSignUp', `${routes.referral}?email=${values.email}`);
    navigate(`${routes.register}?PFemail=${values.email}`);
  };

  return (
    <div className="container md_vishual_wrapper banner_wrapper">
      <div>
        <h2 className="md_title_text home_page_revolution_title">Deliver value for clients faster</h2>
        <h2 className="md_sub_title_text child_titles_text">AI-powered strategy</h2>
      </div>
      <div className="row md_banner_wrapper align-items-center">
        <div className="col-lg-5 col-xl-5">
          <div className="responsive_collaborative_content ">
            <div>
              <p className="md_p_width text-white text_btns_div_p mb-0">
                Ideon AI enables business consultants to
                <br />
                create tailored, data-backed strategies
                <br />
                for clients in minutes, not weeks.
              </p>
            </div>
            <div className="md_text_btns_div">
              <Formik
                initialValues={{ email: '' }}
                // validationSchema={LandingPageValidation}
                onSubmit={redirectToSignUp}
              >
                {({ handleSubmit, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="btns_flex_div">
                      <Field type="text" placeholder="Your email" className="banner_input" name="email" />
                      {/* {errors.email && touched.email ? (
                        <div className="d-block justify-content-left w-100 mx-auto text-start login_lightRed">
                          {errors.email}
                        </div>
                      ) : null} */}
                      <button type="submit" className="trial_btn">
                        Get started for free
                      </button>
                    </div>
                    <span className="md_italilc_text text-white">*No card required</span>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="banner_btn">
              <a className="banner_btn-request" href="https://app.lemcal.com/@ideon/demo">
                Request free demo
              </a>
              <span className="banner_sidetext text-white">to see personalised use case</span>
            </div>
          </div>
        </div>
        <div className="ps-0 ps-lg-5 ps-xl-0 offset-xl-1 col-lg-7 col-xl-6 main-img-resolution">
          {/* <img src={heroSec} alt="homrBanner" className="w-100 responsive_collaborative_image md_hero_banner_img" /> */}
          <video
            autoPlay
            loop
            muted
            playsInline
            controls
            className="w-full w-100 responsive_collaborative_video responsive_collaborative_image"
          >
            <source src={mainVid} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
