/* eslint-disable react/no-unused-prop-types */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { routes } from 'constant';
import { CompanyProfileDropdown, ToggleDropDown } from 'components';
import { getUserSubscription } from 'api/payment';

import 'components/TopNavigationBar/style.css';

const TopNavigationBar = ({
  wrapperClass,
  logoLink,
  isShowCompanyDropdown,
  isShowTrialAlert,
  isShowToggleDropdown,
  isShowProcessingAlert,
  processingAlertText,
  handleChangeCompanyProfile,
}) => {
  const [redirectSub, setRedirectSub] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);

  const { mutate: fetchUserSub } = useQuery({
    queryKey: ['get-user-subscription-expire-days'],
    queryFn: getUserSubscription,
    enabled: isShowTrialAlert,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (
        data.data &&
        data.data.status !== 'active' &&
        data.data.status !== 'free_trial' &&
        data.data.status !== 'trialing'
      ) {
        if (data.data.status === 'expired') setRemainingDays(0);
      } else {
        setRedirectSub(false);
        setRemainingDays(data?.data?.remaining_days || null);
      }
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  useEffect(() => {
    if (fetchUserSub) fetchUserSub();
  }, [fetchUserSub]);

  return (
    <div className={`tnb_containers ${wrapperClass}`}>
      <div className="tnb_logo-wrapper">
        <Link to={logoLink}>
          Ideon<span>AI</span>
        </Link>
      </div>

      <div className="tnb_menu-wrapper">
        {isShowTrialAlert && (
          <div className="tnb_trial-alert-wrapper">
            {remainingDays !== null &&
              (redirectSub === false ? (
                <Link to={routes.stripeEmbedDemo}>{`Your free trial ends in ${remainingDays} days`}</Link>
              ) : (
                <Link to={routes.stripeEmbedDemo}>Your trial has expired</Link>
              ))}
          </div>
        )}

        {isShowProcessingAlert && processingAlertText !== '' && (
          <div className="tnb_processing-alert-wrapper">
            <p>{processingAlertText}</p>
          </div>
        )}

        {isShowCompanyDropdown && (
          <CompanyProfileDropdown
            wrapperClass="tnb_company-profile-dropdown-wrapper"
            handleChangeCompanyProfile={handleChangeCompanyProfile}
          />
        )}

        {isShowToggleDropdown && (
          <div className="tnb_toggle-dropdown-wrapper">
            <ToggleDropDown />
          </div>
        )}
      </div>
    </div>
  );
};

TopNavigationBar.defaultProps = {
  wrapperClass: '',
  logoLink: routes.home,
  isShowCompanyDropdown: false,
  isShowTrialAlert: true,
  isShowToggleDropdown: true,
  isShowProcessingAlert: false,
  processingAlertText: '',
  handleChangeCompanyProfile: () => {},
};

TopNavigationBar.propTypes = {
  wrapperClass: PropTypes.string,
  logoLink: PropTypes.string,
  isShowCompanyDropdown: PropTypes.bool,
  isShowTrialAlert: PropTypes.bool,
  isShowToggleDropdown: PropTypes.bool,
  isShowProcessingAlert: PropTypes.bool,
  processingAlertText: PropTypes.string,
  handleChangeCompanyProfile: PropTypes.func,
};

export default TopNavigationBar;
