import React from 'react';
import HomeComponent from 'fetures/Home';
import Advantage from 'fetures/Home/Components/New/Advantage';
import Banner from 'fetures/Home/Components/New/Banner';
import Challenges from 'fetures/Home/Components/New/Challenges';
import DriveValue from 'fetures/Home/Components/New/DriveValue';
import GetStarted from 'fetures/Home/Components/New/GetStarted';
import LogoSlider from 'fetures/Home/Components/New/LogoSlider';
import MidBanner from 'fetures/Home/Components/New/MidBanner';
import PageTitle from 'components/PageTitle';

const index = () => {
  return (
    <>
      <PageTitle title="Ideon AI | Deliver value for clients faster" />
      {/* <HomeComponent /> */}

      <div className="new_home justify-content-center m-auto ">
        <Banner />
        <MidBanner />
        <LogoSlider />
        <Challenges />
        <Advantage />
        <DriveValue />
        <GetStarted />
      </div>
    </>
  );
};

export default index;
