import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { routes } from 'constant';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

const GetStarted = () => {
  return (
    <div className="container new_getstarted">
      <div className="md_getstartted_wrapper">
        <div className="row md_number_wrapper">
          <h2 className="md_get-title mb-0">Get started with Ideon AI now</h2>
          <div className="col-lg-7 col-xl-7 col-sm-12">
            <div>
              <p className="text-white text_btns_div_p mb-0 md_turn_text md_section_wrapper_info_section_one_text">
                The competitive advantage you need to be successful.
              </p>
            </div>
            <div className="col-lg-5 col-xl-5 col-sm-12 mobile_list ">
              <ul className="md_list">
                <li className="">
                  <FaCheck className="checked_svg" />
                  <span>Deliver value for clients faster</span>
                </li>
                <li className="flex">
                  <FaCheck className="checked_svg" /> <span>Build client trust</span>
                </li>
                <li className="flex">
                  <FaCheck className="checked_svg" /> <span>Unlock AI insights</span>
                </li>
              </ul>
            </div>
            <div className="md_text_btns">
              <div className="btns_flex_div">
                {/* <span> */}
                <Link to={routes.register} className="trial_btn">
                  Get started for free
                </Link>
                {/* </span>
            <span> */}
                <Link to="https://app.lemcal.com/@ideon/demo" className="book_btn">
                  Request free demo
                </Link>
                {/* </span> */}
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xl-5 col-sm-12 desktop_list">
            <ul className="md_list">
              <li>
                <FaCheck className="checked_svg" />
                Deliver value for clients faster
              </li>
              <li>
                <FaCheck className="checked_svg" /> Build client trust
              </li>
              <li>
                <FaCheck className="checked_svg" /> Unlock AI insights
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
