import React, { useEffect, useRef, useState } from 'react';
import { routes } from 'constant';
import { TopNavigationBar } from 'components';
import DetailModal from './Components/DetailModal';
import { NewWrokSpaceCard } from './Components';

import './style.css';

const Home = () => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);

  const handleClose = () => setShow(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="dashboard_page-wrapper">
      <TopNavigationBar isShowCompanyDropdown logoLink={routes.home} handleChangeCompanyProfile={setCompanyProfile} />

      <div className="dashboard_page-container m-auto">
        {show === true && (
          <div ref={ref}>
            <DetailModal show={show} handleClose={handleClose} />
          </div>
        )}

        <NewWrokSpaceCard companyProfile={companyProfile} />
      </div>
    </div>
  );
};

export default Home;
