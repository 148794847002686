import React from 'react';
import WorkspaceEdit from 'fetures/Workspace/edit';
import PageTitle from 'components/PageTitle';

const WorkspaceEditPage = () => {
  return (
    <>
      <PageTitle title="Workspace Edit" />
      <WorkspaceEdit />
    </>
  );
};

export default WorkspaceEditPage;
