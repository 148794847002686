/* eslint-disable simple-import-sort/imports */
import React from 'react';

import PageTitle from 'components/PageTitle';
import Banner from './Components/New/Banner';
import LogoSlider from './Components/New/LogoSlider';
import MidBanner from './Components/New/MidBanner';
import './style.css';
import Challenges from './Components/New/Challenges';
import Advantage from './Components/New/Advantage';
import DriveValue from './Components/New/DriveValue';
import GetStarted from './Components/New/GetStarted';

const LandingPage = () => {
  return (
    <>
      <PageTitle title="Ideon AI | Consulting" />
      <div className="new_home justify-content-center m-auto ">
        <Banner />
        <MidBanner />
        <LogoSlider />
        <Challenges />
        <Advantage />
        <DriveValue />
        <GetStarted />
      </div>
    </>
  );
};

export default LandingPage;
