import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import AnimatedNumber from 'animated-number-react';
import { ModalComponent } from 'components';
import { getConfigData } from 'api/config';
// import { ModalComponent } from 'components';
import innovationAmico from 'assets/Innovation-amico.png';

import './style.css';

const MidBanner = () => {
  const [countersData, setCountersData] = useState({});
  const [strategiesCreated, setStrategiesCreated] = useState(0);
  const [hoursSaved, setHoursSaved] = useState(0);
  const [value, setValue] = useState(0);
  function formatNumber(num, shortner = '') {
    if (num < 1000) {
      return num;
    }
    if (num < 1000000 || shortner === 'K') {
      return `${(num / 1000).toFixed(0)}K`;
    }
    if (num < 1000000000 || shortner === 'M') {
      return `${(num / 1000000).toFixed(0)}M`;
    }
    return `${(num / 1000000000).toFixed(0)}B`;
  }

  function intToString(value) {
    const valueN = parseInt(value, 10);
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(`${valueN}`.length / 3);
    let shortValue = parseFloat((suffixNum !== 0 ? valueN / 1000 ** suffixNum : valueN).toPrecision(2));
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }
  const formatValue = (value) => formatNumber(value, 'K');
  const formatValueComma = (value) =>
    parseInt(value, 10)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  useEffect(() => {
    const fetchData = async () => {
      const configData = await getConfigData('landing_page_counters');
      setCountersData(configData);
    };
    fetchData();
  }, []);
  return (
    <div className="container home_banner steps_section">
      <div className="steps_section_containe mid_banner_container">
        <div className="md_no steps_section_container">
          <h2 className="revolution_title" data-content={`${strategiesCreated}`}>
            {countersData?.strategies_created ? (
              <AnimatedNumber
                value={countersData?.strategies_created || 517}
                update={(v) => {
                  setStrategiesCreated(formatValueComma(v.animatables[0].target.animatedValue));
                }}
                formatValue={formatValueComma}
                delay={500}
                duration={2000}
              />
            ) : (
              <div>0</div>
            )}
          </h2>
          <h6 className="md_card_text child_titles_text">Strategies created</h6>
        </div>
        <div className="md_no steps_section_container">
          <h2 className="revolution_title" data-content={`${hoursSaved}`}>
            {countersData?.hours_saved ? (
              <AnimatedNumber
                value={countersData?.hours_saved || 517}
                update={(v) => {
                  setHoursSaved(formatValueComma(v.animatables[0].target.animatedValue));
                }}
                formatValue={formatValueComma}
                delay={500}
                duration={2000}
              />
            ) : (
              <div>0</div>
            )}
          </h2>
          <h6 className="md_card_text child_titles_text">Hours saved</h6>
        </div>
        <div className="md_no steps_section_container">
          <h2 className="revolution_title" data-content={`$${value}`}>
            $
            {countersData?.value_added ? (
              <AnimatedNumber
                value={countersData?.value_added || 517}
                update={(v) => {
                  setValue(formatValue(v.animatables[0].target.animatedValue));
                }}
                formatValue={formatValue}
                delay={500}
                duration={2000}
              />
            ) : (
              <div>0</div>
            )}
          </h2>
          <h6 className="md_card_text child_titles_text">Value added</h6>
        </div>
      </div>
    </div>
  );
};

export default MidBanner;
