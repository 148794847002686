/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { COMPANY_PROFILE_REDIRECT_URL_KEY, DEFAULT_COMPANY_PROFILE, routes, TOAST_CONFIG } from 'constant';
import { AttributesForm, CompanyObjectForm } from 'fetures/CompanyProfile/Components';
import { formInitialValues } from 'fetures/CompanyProfile/constant';
import { TopNavigationBar } from 'components';
import { getCompanyObject, getFormAttributes, storeCompanyProfile } from 'api/companyProfile';
import { catchBlock } from 'utils';

import 'fetures/CompanyProfile/style.css';
import { getLoadingMessages } from 'api/config';

const CompanyProfile = () => {
  const navigate = useNavigate();

  const [attributes, setAttributes] = useState([]);
  const [companyAttributes, setCompanyAttributes] = useState([]);
  const [companyObject, setCompanyObject] = useState({});
  const [isConfirmFormShow, setIsConfirmFormShow] = useState(false);
  const [companyObjectFormInitialValues, setCompanyObjectFormInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [logoIconUrl, setLogoIconUrl] = useState(routes.myAccount);
  const [successRedirectUrl, setSuccessRedirectUrl] = useState(routes.myAccount);
  const [loadingMessages, setLoadingMessages] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState(null);

  const { isFetching: isFetchingLoadingMessages } = useQuery(['get-loading-messages'], () => getLoadingMessages(), {
    refetchOnMount: 'always',
    onSuccess: (response) => {
      setLoadingMessages(response?.messages || null);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: getFormAttributesApi, isLoading: getFormAttributesApiLoading } = useMutation(getFormAttributes, {
    onSuccess: (res) => {
      setAttributes(res ? res?.attributes || [] : []);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: getCompanyObjectApi, isLoading: getCompanyObjectApiLoading } = useMutation(getCompanyObject, {
    onMutate: () => {
      setLoaderMessage(loadingMessages?.company_profile_processed_data || null);
    },
    onSuccess: (res) => {
      setCompanyAttributes(res ? res?.attributes || [] : []);
      setCompanyObject(res ? res?.companyObject || {} : {});

      setCompanyObjectFormInitialValues({
        attributes: Object.keys(res?.companyObject || {}).reduce((acc, key) => {
          const foundAttribute = (res?.attributes || []).find((a) => a.objectKey === key);
          if (foundAttribute) {
            acc[key] = res?.companyObject[key];
          }
          return acc;
        }, {}),
      });

      setIsConfirmFormShow(true);
    },
    onError: (error) => {
      catchBlock(error);
    },
    onSettled: () => {
      setLoaderMessage(null);
    },
  });

  const { mutate: storeCompanyProfileApi, isLoading: storeCompanyProfileLoading } = useMutation(storeCompanyProfile, {
    onSuccess: (res) => {
      if (res?.data) {
        localStorage.setItem(
          DEFAULT_COMPANY_PROFILE,
          JSON.stringify({
            value: res?.data?.id || null,
            label: res?.data?.name || '',
          }),
        );
      }

      toast.success(res.message, TOAST_CONFIG);
      setTimeout(() => {
        // navigate(successRedirectUrl);
        navigate(routes.dashBoard);
      }, 800);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const handleSubmitForm = async (values) => {
    try {
      const requestData = Object.keys(values?.attributes || {})
        ?.map((key) => {
          if (values.attributes[key] && values.attributes[key] !== '')
            return { value: values.attributes[key], objectKey: key };
          return false;
        })
        .filter((item) => item !== false);

      await getCompanyObjectApi({ attributes: requestData });
    } catch (error) {
      catchBlock(error);
    }
  };

  const handleSubmitCompanyObjectForm = async (values) => {
    try {
      const attributes = values?.attributes || {};
      const websiteChanged = attributes.website && attributes.website !== companyObject?.website;

      const prepareRequestData = (companyObjectData) => {
        return {
          attributes: companyAttributes,
          companyObject: { ...companyObject, ...companyObjectData },
          name: companyObjectData?.name || '',
        };
      };

      if (websiteChanged) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to refresh the website data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, refresh it!',
        }).then(async (result) => {
          setIsLoading(true);

          if (result.isConfirmed) {
            const requestData = Object.keys(attributes).map((key) => ({
              value: attributes[key],
              objectKey: key,
            }));

            const res = await getCompanyObject({ attributes: requestData });
            setCompanyObject(res?.companyObject ? res?.companyObject || {} : {});

            setCompanyObjectFormInitialValues({
              attributes: Object.keys(res?.companyObject || {}).reduce((acc, key) => {
                const foundAttribute = (companyAttributes || []).find((a) => a.objectKey === key);
                if (foundAttribute) {
                  acc[key] = res?.companyObject[key];
                }
                return acc;
              }, {}),
            });

            setIsLoading(false);

            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            const companyObjectData = { ...attributes };
            const requestData = prepareRequestData(companyObjectData);

            await storeCompanyProfileApi(requestData);
          }
        });
      } else {
        setIsLoading(true);

        const companyObjectData = { ...attributes };
        const requestData = prepareRequestData(companyObjectData);

        await storeCompanyProfileApi(requestData);
      }
    } catch (error) {
      catchBlock(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (getFormAttributesApi) getFormAttributesApi();
  }, [getFormAttributesApi]);

  useEffect(() => {
    const redirectUrl = localStorage.getItem(COMPANY_PROFILE_REDIRECT_URL_KEY);

    if (redirectUrl === routes.mindMap) {
      setLogoIconUrl(routes.dashBoard);
      setSuccessRedirectUrl(routes.mindMap);
    } else if (redirectUrl) {
      setLogoIconUrl(redirectUrl);
      setSuccessRedirectUrl(redirectUrl);
    }

    return () => {
      localStorage.removeItem(COMPANY_PROFILE_REDIRECT_URL_KEY);
    };
  }, []);

  return (
    <>
      {(getFormAttributesApiLoading ||
        getCompanyObjectApiLoading ||
        storeCompanyProfileLoading ||
        isFetchingLoadingMessages ||
        isLoading) && (
        <div className="cp-spinner-wrapper">
          <Spinner animation="border" className="cp-spiner" />

          {loaderMessage && (
            <div className="cp-spinner-message">
              {loaderMessage.split('\n').map((msg, index) => (
                <p key={index}>{msg}</p>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="bg-transparent cp-container">
        <div className="cp-content">
          <TopNavigationBar isShowCompanyDropdown={false} logoLink={logoIconUrl} />

          {isConfirmFormShow ? (
            <Formik
              onSubmit={handleSubmitCompanyObjectForm}
              initialValues={companyObjectFormInitialValues}
              enableReinitialize
            >
              <CompanyObjectForm attributes={companyAttributes} />
            </Formik>
          ) : (
            <Formik onSubmit={handleSubmitForm} initialValues={formInitialValues} enableReinitialize>
              <AttributesForm attributes={attributes} />
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
