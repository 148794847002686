import React from 'react';
import img9 from 'assets/home-new/drive-value/auction.svg';
import img6 from 'assets/home-new/drive-value/automation.svg';
import img8 from 'assets/home-new/drive-value/customer-care.svg';
import img14 from 'assets/home-new/drive-value/global-business.svg';
import img3 from 'assets/home-new/drive-value/hand.svg';
import img4 from 'assets/home-new/drive-value/human-resources.svg';
import img10 from 'assets/home-new/drive-value/megaphone.svg';
import img12 from 'assets/home-new/drive-value/new-product.svg';
import img2 from 'assets/home-new/drive-value/online-shop.svg';
import img7 from 'assets/home-new/drive-value/research.svg';
import img11 from 'assets/home-new/drive-value/supply-chain.svg';
import img13 from 'assets/home-new/drive-value/sustainability.svg';
import img5 from 'assets/home-new/drive-value/technical-support.svg';
import img1 from 'assets/home-new/drive-value/to-do-list.svg';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

const DriveValue = () => {
  return (
    <div className="container">
      <div className=" business_areas_wrapper">
        <h2 className="business_areas-title">Drive value in every business area</h2>
        <ul className="business_areas">
          <li>
            <img src={img1} alt="Strategic Planning" />
            <span className="text-white">Strategic Planning</span>
          </li>
          <li>
            <img src={img6} alt="Business Operations" />
            <span className="text-white">Business Operations</span>
          </li>
          <li>
            <img src={img11} alt="Procurement" />
            <span className="text-white">Procurement</span>
          </li>
          <li>
            <img src={img2} alt="Sales and Marketing" />
            <span className="text-white">Sales and Marketing</span>
          </li>
          <li>
            <img src={img7} alt="Research and Development (R&D)" />
            <span className="text-white">Research and Development (R&D)</span>
          </li>
          <li>
            <img src={img12} alt="Product Management" />
            <span className="text-white">Product Management</span>
          </li>
          <li>
            <img src={img3} alt="Finance" />
            <span className="text-white">Finance</span>
          </li>
          <li>
            <img src={img8} alt="Customer Service" />
            <span className="text-white">Customer Service</span>
          </li>
          <li>
            <img src={img13} alt="Environmental Social and Governance (ESG)" />{' '}
            <span className="text-white">Environmental, Social, and Governance (ESG)</span>
          </li>
          <li>
            <img src={img4} alt="Human Resources (HR)" />
            <span className="text-white">Human Resources (HR)</span>
          </li>
          <li>
            <img src={img9} alt="Legal" />
            <span className="text-white">Legal</span>
          </li>

          <li>
            <img src={img14} alt="International Business" />
            <span className="text-white">International Business</span>
          </li>
          <li>
            <img src={img5} alt="Information Technology (IT)" />
            <span className="text-white">Information Technology (IT)</span>
          </li>
          <li>
            <img src={img10} alt="Public Relations (PR)" />
            <span className="text-white">Public Relations (PR)</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DriveValue;
