import Api from './axios.config';

export const getLoadingMessages = async (data = {}) => {
  const response = await Api.get('/config/loading-messages', { params: data });
  return response.data;
};

export const getConfigData = async (configName) => {
  const response = await Api.get(`/config/get/${configName}`);
  return response.data;
};
