/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';
import { RiCheckFill, RiCloseLine } from 'react-icons/ri';
import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { AddComments as postMindmapComments, getMindmapComments } from 'api/mindMamp';
import CommentCard from '../CommentCard';

import './style.css';

const Comments = ({ nodeMoreActionsData }) => {
  const commentInput = useRef(null);

  const [nodeComments, setNodeComments] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { isLoading: isGetMindmapCommentsApiLoading, refetch: refetchMindmapComments } = useQuery({
    queryKey: [
      'get-workspace-node-comments',
      {
        work_space_id: nodeMoreActionsData?.workspace_id,
        node_id: nodeMoreActionsData?.node_id,
      },
    ],
    queryFn: getMindmapComments,
    onSuccess: (response) => {
      if (response?.data && response?.data !== null) setNodeComments(response?.data || []);
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  const { mutate: postMindmapCommentsApi, isLoading: isPostMindmapCommentsApiLoading } = useMutation(
    postMindmapComments,
    {
      onSuccess: (res) => {
        refetchMindmapComments();
      },
      onError: (error) => {
        catchBlock(error);
      },
    },
  );

  const handleCommentCreationAction = async (action) => {
    if (action === 'cancel') {
      if (commentInput.current) commentInput.current.value = '';
    } else if (action === 'save' && commentInput.current && commentInput.current.value !== '') {
      const comment = commentInput.current.value;

      postMindmapCommentsApi({
        work_space_id: nodeMoreActionsData?.workspace_id,
        node_id: nodeMoreActionsData?.node_id,
        comment,
      });
    }

    setIsCreateModalOpen(false);
  };

  return (
    <div className="maap-comments-wrapper">
      {(isGetMindmapCommentsApiLoading || isPostMindmapCommentsApiLoading) && (
        <div className="maap-comments-spinner-wrapper">
          <Spinner animation="border" className="maap-comments-spinner" />
        </div>
      )}

      <h4>Comments</h4>

      <div className="maap-comments-list">
        {nodeComments && nodeComments.map((comment, index) => <CommentCard key={index} comment={comment} />)}

        <div className="maap-comments-creation-card">
          {isCreateModalOpen ? (
            <div className="maap-comments-creation-form">
              <div className="maap-comments-creation-form-header">
                <input
                  ref={commentInput}
                  className="maap-comments-title-input"
                  type="text"
                  placeholder="Enter Comment"
                  autoFocus
                />

                <div className="maap-comments-creation-form-action-button">
                  <RiCheckFill onClick={() => handleCommentCreationAction('save')} color="#f05436" size="22" />
                  <RiCloseLine onClick={() => handleCommentCreationAction('cancel')} color="#f05436" size="22" />
                </div>
              </div>
            </div>
          ) : (
            <div className="maap-comments-creation-add-button" onClick={() => setIsCreateModalOpen(true)}>
              <FiPlus color="#f05436" size="22" />
              <p>Add</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Comments.defaultProps = {
  nodeMoreActionsData: PropTypes.object,
};

Comments.propTypes = {
  nodeMoreActionsData: PropTypes.object,
};
export default Comments;
