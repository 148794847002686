/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RiCheckFill, RiCloseLine, RiDeleteBin5Fill } from 'react-icons/ri';
import PropTypes from 'prop-types';

import './style.css';

const ActionPlanCard = ({
  index,
  actionPlan,
  isActionPlanInEditMode,
  handleDeleteActionPlan,
  dragHandleProps,
  handleUpdateActionPlan,
}) => {
  const titleInput = useRef(null);
  const actionPlanInput = useRef([]);

  const [isCollapse, setIsCollapse] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleStartEditActionPlan = (input) => {
    setIsEditModalOpen(true);

    setTimeout(() => {
      if (input === 'titleInput' && titleInput.current) titleInput.current.focus();
      else if (actionPlanInput.current.length > 0) actionPlanInput.current[input].focus();
    }, 200);
  };

  const handleEditActionPlanData = async (action) => {
    if (action === 'save' && titleInput.current && titleInput.current.value !== '') {
      const actionPlanBody = [];

      actionPlan.body.map((item, index) => {
        if (actionPlanInput.current.length > 0 && actionPlanInput.current[index].value !== '')
          actionPlanBody.push({ ...item, content: actionPlanInput.current[index].value });
      });

      const actionPlanData = { ...actionPlan, main: titleInput.current.value, body: actionPlanBody };

      await handleUpdateActionPlan(index - 1, actionPlanData);
    }

    setIsEditModalOpen(false);
  };

  useEffect(() => {
    if (isActionPlanInEditMode) {
      setIsCollapse(true);
      handleEditActionPlanData('cancel');
    }
  }, [isActionPlanInEditMode]);

  return (
    <div className="maap-card-container">
      {isActionPlanInEditMode && (
        <div className="maap-card-action-button" {...dragHandleProps}>
          <GiHamburgerMenu color="#f05436" size="22" />
        </div>
      )}

      {isCollapse ? (
        <div onClick={(e) => setIsCollapse(!isCollapse)} className="maap-card">
          <div className="maap-card-header">
            <p>
              {index}. {actionPlan?.main || ''}
            </p>

            <FiPlus color="#f05436" size="22" />
          </div>
        </div>
      ) : (
        <div className="maap-card">
          <div className="maap-card-header">
            {isEditModalOpen ? (
              <>
                <p>{index}.</p>

                <input
                  ref={titleInput}
                  className="maap-card-edit-form-title-input"
                  type="text"
                  placeholder="Enter Title"
                  defaultValue={actionPlan?.main || ''}
                />
              </>
            ) : (
              <p onClick={() => handleStartEditActionPlan('titleInput')}>
                {index}. {actionPlan?.main || ''}
              </p>
            )}

            {isEditModalOpen ? (
              <div className="maap-card-edit-form-action-button">
                <RiCheckFill onClick={() => handleEditActionPlanData('save')} color="#f05436" size="22" />
                <RiCloseLine onClick={() => handleEditActionPlanData('cancel')} color="#f05436" size="22" />
              </div>
            ) : (
              <FiMinus onClick={() => setIsCollapse(!isCollapse)} color="#f05436" size="22" />
            )}
          </div>

          <div className="maap-card-description">
            {actionPlan?.body &&
              actionPlan?.body?.map((item, index) => (
                <div key={index}>
                  <h5>{item?.heading || ''} :</h5>

                  {isEditModalOpen ? (
                    <textarea
                      ref={(el) => (actionPlanInput.current[index] = el)}
                      className="maap-card-edit-form-description-input"
                      placeholder={`Enter ${item?.heading}`}
                      defaultValue={item?.content || ''}
                      rows={4}
                    />
                  ) : (
                    <p onClick={() => handleStartEditActionPlan(index)}>
                      {item?.heading?.toLowerCase() === 'tools to use'
                        ? (item?.content || '').split('\n').map((content, contentIndex) => (
                            <React.Fragment key={contentIndex}>
                              {content && (
                                <span>
                                  {content.split(/(https?:\/\/[^\s)]+)/gi).map((segment, index) => {
                                    if (/^https?:\/\/[^\s)]+/i.test(segment)) {
                                      const cleanURL = segment.replace(/[.,)]+$/, '');
                                      return (
                                        <a key={index} href={cleanURL} target="_blank" rel="noopener noreferrer">
                                          {cleanURL}
                                        </a>
                                      );
                                    }
                                    return <React.Fragment key={index}>{segment}</React.Fragment>;
                                  })}
                                </span>
                              )}
                              <br />
                            </React.Fragment>
                          ))
                        : `${item?.content || ''}`}
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}

      {isActionPlanInEditMode && (
        <RiDeleteBin5Fill
          onClick={() => handleDeleteActionPlan(index - 1)}
          className="maap-card-delete-button"
          color="#f05436"
          size="22"
        />
      )}
    </div>
  );
};

ActionPlanCard.defaultProps = {
  index: PropTypes.number,
  actionPlan: PropTypes.object,
  isActionPlanInEditMode: PropTypes.bool,
  handleDeleteActionPlan: PropTypes.func,
  dragHandleProps: PropTypes.any,
  handleUpdateActionPlan: PropTypes.func,
};

ActionPlanCard.propTypes = {
  index: PropTypes.number,
  actionPlan: PropTypes.object,
  isActionPlanInEditMode: PropTypes.bool,
  handleDeleteActionPlan: PropTypes.func,
  dragHandleProps: PropTypes.any,
  handleUpdateActionPlan: PropTypes.func,
};
export default ActionPlanCard;
